import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../components/Layout";
import ProtectedRoute from "./protectedRoute";
/* End Routes */

const Routes = () => (
  <Layout>
    <Suspense fallback={<div />}>
      <Switch>
        {ProtectedRoute().map((value:any, key:any) => (
          <Route
            key={key}
            exact={value.exact}
            path={value.path}
            component={value.component}
          />
        ))}
      </Switch>
    </Suspense>
  </Layout>)

const RootRoutes = () => <Route component={Routes} />;

export default RootRoutes;
