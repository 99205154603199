import { all } from 'redux-saga/effects';

import general from './general';
import document from './document';
import address from './address';
import addressHome from './addressHome';
import bank from './bank';
import occupation from './occupation';
import personal1 from './personal1';
import personal2 from './personal2';
import tax from './tax';
import thankyounew from './thankyounew';
import account from "./account";
import credential from "./credential";
import navigation from "./navigation";
import loan from "./loan";
import utility from "./utility";
import instrumentsPartner from "./instrumentsPartner";

export default function* rootSaga() {
  yield all([
    credential,
    account,
    general,
    document,
    address,
    addressHome,
    bank,
    occupation,
    personal1,
    personal2,
    tax,
    thankyounew,
    navigation,
    instrumentsPartner,
    utility,
    loan
  ]);
}
