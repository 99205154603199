import {
  GET_DOCUMENT_FAILED,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_REQUEST,
  POST_DOCUMENT_FAILED,
  POST_DOCUMENT_REQUEST,
  SET_STATE_DOCUMENT,
  SET_STATE_DATA_DOCUMENT
} from '../actions/document';

const initialState = {
  loading: false,
  imageUrl: "",
  formValidation: {
    identityImage: {
      isError: false,
      errorMessage: "",
    },
    identityNumber: {
      isError: false,
      errorMessage: "",
    },
    identityDate: {
      isError: false,
      errorMessage: "",
    }
  },
  buttonLoading: false,
  buttonDisabled: true,
  base64ImageDefault: "",
  base64Image: "",
  dataDefault: {},
  data: { identity_number: "", identity_date: '' },
  errorField: null

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT_REQUEST:
      return {
        ...state, ...initialState,
        loading: true,
      };
    case GET_DOCUMENT_SUCCESS:
      let data = action.data
      const datestate = action.data.identity_date
      let subDate = datestate != null ? datestate.split("-") : ""
      const newData = {
        ...state.data, ...data,
        identity_date: datestate ,
        identity_number: action.data.identity_number,
        inputYear: datestate != null ? Number(subDate[0]) :"",
        inputMonth: datestate != null ? Number(subDate[1]) :"",
        inputDate: datestate != null ? Number(subDate[2]) :"",
      }
      return {
        ...state,
        base64ImageDefault: action.base64,
        base64Image: action.base64,
        imageUrl: action.image,
        loading: false,
        buttonDisabled: false,
        dataDefault: newData,
        data: newData
      };
    case GET_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false
      }
    case SET_STATE_DOCUMENT:
      return { ...state, [action.name]: action.value }
    case SET_STATE_DATA_DOCUMENT:
      return { ...state, data: { ...state.data, [action.name]: action.value } }
    case POST_DOCUMENT_REQUEST:
      return {
        ...state, buttonLoading: true
      }
    case POST_DOCUMENT_FAILED:
      return {
        ...state, buttonLoading: false,
        errorField: action.error
      }
    case "NOT_LOADING":
      return { ...state, buttonLoading: false }
    default:
      return state
  }
}