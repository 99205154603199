import service from './service';
import { getEndpoints } from "../helpers";

export const getLoans = (data) => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + "api/loans/lists?user_id=" + data.userId + "&order=date-desc" + data.order + "&limit=" + data.limit + "&offset=" + data.offset);
};
export const getLoansFromAdmin = (data) => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + "api/loans/admin/proposal?user_id=" + data.userId+"&order=date-desc" + data.order + "&limit=" + data.limit + "&offset=" + data.offset +"&partner="+ data.partner);
};

export const getLoanDetailById = (id) => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + "api/loans/proposal/" + id);
};

export const postSubmitLoan = (data) => {
  return service.postLoan(getEndpoints.loanServiceUrl + "api/loans/proposal", data);
};

export const validationLoan = (fileId, userId, status, partnerName,skip,role) => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + `api/loans/spreadsheet/${fileId}?user_id=${userId}&status=${status}&partner_name=${partnerName}&skip=${skip}&role=${role}`)
}
export const revisionLoan = (data, id) => {
  return service.postLoan(getEndpoints.loanServiceUrl + `api/loans/revision/${id}`, data)
}

export const approvedLoanApi = (fileId,fileIdRisk, data,emailOtp) => {
  return service.postLoan(getEndpoints.loanServiceUrl + `api/loans/proposal-approved/${fileId}?file_id_risk=${fileIdRisk}&staff_email=${emailOtp}`, data);
};

export const approvedLoanBypassApi = ( data,emailOtp) => {
  return service.postLoan(getEndpoints.loanServiceUrl + `api/loans/proposal-approved-bypass?staff_email=${emailOtp}`, data);
};

export const rejectedLoanApi = (id, fileIdRisk, data,emailOtp) => {
  return service.postLoan(getEndpoints.loanServiceUrl + `api/loans/proposal-rejected/${id}?file_id_risk=${fileIdRisk}&staff_email=${emailOtp}`, data);
};

export const getLoanFolderApi = () => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + "api/loans/folder");
};

export const getLoanFolderByUserIdApi = (userId) => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + "api/loans/folder/"+userId);
};

export const getLoanFolderByPartnerIdApi = (partnerId) => {
  return service.extGetLoan(getEndpoints.loanServiceUrl + "api/loans/folder-partner/"+partnerId);
};
