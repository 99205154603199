import { defaultActionCreator } from "./actionCreator";

export const GET_LOAN_REQUEST = 'GET_LOAN_REQUEST'
export const GET_LOAN_SUCCESS = 'GET_LOAN_SUCCESS'
export const GET_LOAN_FAILED = 'GET_LOAN_FAILED'
export const getLoanRequest = defaultActionCreator(GET_LOAN_REQUEST, 'data')
export const getLoanSuccess = defaultActionCreator(GET_LOAN_SUCCESS, 'data')
export const getLoanFailed = defaultActionCreator(GET_LOAN_FAILED, 'error')

export const GET_LOAN_FROM_ADMIN_REQUEST = 'GET_LOAN_FROM_ADMIN_REQUEST'
export const getLoanFromAdminRequest = defaultActionCreator(GET_LOAN_FROM_ADMIN_REQUEST, 'data')


export const GET_LOAN_DETAIL_BY_ID_REQUEST = 'GET_LOAN_DETAIL_BY_ID_REQUEST'
export const GET_LOAN_DETAIL_BY_ID_SUCCESS = 'GET_LOAN_DETAIL_BY_ID_SUCCESS'
export const GET_LOAN_DETAIL_BY_ID_FAILED = 'GET_LOAN_DETAIL_BY_ID_FAILED'
export const getLoanDetailByIdRequest = defaultActionCreator(GET_LOAN_DETAIL_BY_ID_REQUEST, 'id', "modal")
export const getLoanDetailByIdSuccess = defaultActionCreator(GET_LOAN_DETAIL_BY_ID_SUCCESS, 'data')
export const getLoanDetailByIdFailed = defaultActionCreator(GET_LOAN_DETAIL_BY_ID_FAILED, 'error')


export const POST_SUBMIT_LOAN_REQUEST = 'POST_SUBMIT_LOAN_REQUEST'
export const POST_SUBMIT_LOAN_SUCCESS = 'POST_SUBMIT_LOAN_SUCCESS'
export const POST_SUBMIT_LOAN_FAILED = 'POST_SUBMIT_LOAN_FAILED'
export const postSubmitLoanRequest = defaultActionCreator(POST_SUBMIT_LOAN_REQUEST, 'data', 'modal','fileId', 'userId', "status", "partnerName","skip","role", 'modalValid','locationValid')
export const postSubmitLoanSuccess = defaultActionCreator(POST_SUBMIT_LOAN_SUCCESS, 'data')
export const postSubmitLoanFailed = defaultActionCreator(POST_SUBMIT_LOAN_FAILED, 'error')

export const VALIDATION_LOAN_REQUEST = 'VALIDATION_LOAN_REQUEST'
export const VALIDATION_LOAN_SUCCESS = 'VALIDATION_LOAN_SUCCESS'
export const VALIDATION_LOAN_FAILED = 'VALIDATION_LOAN_FAILED'
export const validationLoanRequest = defaultActionCreator(VALIDATION_LOAN_REQUEST, 'fileId', 'userId', "status", "partnerName","skip","role", 'modal', 'location')
export const validationLoanSuccess = defaultActionCreator(VALIDATION_LOAN_SUCCESS, 'data')
export const validationLoanFailed = defaultActionCreator(VALIDATION_LOAN_FAILED, 'error')

export const SORTER_FIELD_LOAN = 'SORTER_FIELD_LOAN'
export const sorterField = defaultActionCreator(SORTER_FIELD_LOAN, 'sorter')


export const POST_REVISION_LOAN_REQUEST = 'POST_REVISION_LOAN_REQUEST'
export const POST_REVISION_LOAN_SUCCESS = 'POST_REVISION_LOAN_SUCCESS'
export const POST_REVISION_LOAN_FAILED = 'POST_REVISION_LOAN_FAILED'
export const postRevisionoanRequest = defaultActionCreator(POST_REVISION_LOAN_REQUEST, 'data', 'id', 'modal','fileId', 'userId', "status", "partnerName","skip","role", 'modalValid','locationValid')
export const postRevisionoanSuccess = defaultActionCreator(POST_REVISION_LOAN_SUCCESS, 'data')
export const postRevisionoanFailed = defaultActionCreator(POST_REVISION_LOAN_FAILED, 'error')

export const POST_APPROVED_LOAN_REQUEST = 'POST_APPROVED_LOAN_REQUEST'
export const postApprovedLoanRequest = defaultActionCreator(POST_APPROVED_LOAN_REQUEST, 'fileId','fileIdRisk', 'data','location','userId', "status", "partnerName","skip","role", 'modalValid','locationValid','emailOtp')

export const POST_APPROVED_LOAN_BYPASS_REQUEST = 'POST_APPROVED_LOAN_BYPASS_REQUEST'
export const postApprovedLoanBypassRequest = defaultActionCreator(POST_APPROVED_LOAN_BYPASS_REQUEST, 'data','location','userId', "status", "partnerName","skip","role", 'modalValid','locationValid','emailOtp')

export const PUT_REJECTED_LOAN_REQUEST = 'PUT_REJECTED_LOAN_REQUEST'
export const putRejectedLoanRequest = defaultActionCreator(PUT_REJECTED_LOAN_REQUEST, 'id', 'fileIdRisk','location','data','userId', "status", "partnerName","skip","role", 'modalValid','locationValid','emailOtp')

export const GET_LOAN_FOLDER_REQUEST = 'GET_LOAN_FOLDER_REQUEST'
export const GET_LOAN_FOLDER_SUCCESS = 'GET_LOAN_FOLDER_SUCCESS'
export const GET_LOAN_FOLDER_FAILED = 'GET_LOAN_FOLDER_FAILED'
export const getLoanFolderRequest = defaultActionCreator(GET_LOAN_FOLDER_REQUEST)
export const getLoanFolderSuccess = defaultActionCreator(GET_LOAN_FOLDER_SUCCESS, 'data')
export const getLoanFolderFailed = defaultActionCreator(GET_LOAN_FOLDER_FAILED, 'error')


export const FILTER_PARTNER_CHANGE = 'FILTER_PARTNER_CHANGE'
export const filterPartnerChange = defaultActionCreator(FILTER_PARTNER_CHANGE, 'data')

export const LOAN_DEFAULT_STATE = 'LOAN_DEFAULT_STATE'
export const loanDefaultState = defaultActionCreator(LOAN_DEFAULT_STATE, 'data')


export const GET_LOAN_FOLDER_BY_USER_ID_REQUEST = 'GET_LOAN_FOLDER_BY_USER_ID_REQUEST'
export const GET_LOAN_FOLDER_BY_USER_ID_SUCCESS = 'GET_LOAN_FOLDER_BY_USER_ID_SUCCESS'
export const GET_LOAN_FOLDER_BY_USER_ID_FAILED = 'GET_LOAN_FOLDER_BY_USER_ID_FAILED'
export const getLoanFolderByUserIdRequest = defaultActionCreator(GET_LOAN_FOLDER_BY_USER_ID_REQUEST, 'userId')
export const getLoanFolderByUserIdSuccess = defaultActionCreator(GET_LOAN_FOLDER_BY_USER_ID_SUCCESS, 'data')
export const getLoanFolderByUserIdFailed = defaultActionCreator(GET_LOAN_FOLDER_BY_USER_ID_FAILED, 'error')

export const GET_LOAN_FOLDER_BY_PARTNER_ID_REQUEST = 'GET_LOAN_FOLDER_BY_PARTNER_ID_REQUEST'
export const getLoanFolderByPartnerRequest = defaultActionCreator(GET_LOAN_FOLDER_BY_PARTNER_ID_REQUEST, 'partnerId')

