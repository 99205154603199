import { GET_PROGRESS_REQUEST, GET_PROGRESS_SUCCESS, GET_PROGRESS_FAILED, POST_PROFILE_COMPLETED_REQUEST, POST_PROFILE_COMPLETED_SUCCESS, POST_PROFILE_COMPLETED_FAILED } from "../actions/navigation";

const initialState = {
    total: 0,
    loading: true,
    loadingbtn: false,
    data: null,
    isEmpty: true,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROGRESS_REQUEST:
            return { ...state, loading: true }
        case GET_PROGRESS_SUCCESS:
            const newData = action.data
            let total = 0
            let isEmpty = true
            Object.keys(newData).map((key) => {
                if (!newData[key]) {
                    isEmpty = false
                } else {
                    total += newData[key].length
                }
            }
            );
            return {
                ...state,
                data: newData,
                total,
                isEmpty,
                loading: false
            };
        case GET_PROGRESS_FAILED:
            return { ...state, loading: true }
        case POST_PROFILE_COMPLETED_REQUEST:
            return { ...state, loadingbtn: true }
        case POST_PROFILE_COMPLETED_SUCCESS:
            return { ...state, loadingbtn: false }
        case POST_PROFILE_COMPLETED_FAILED:
            return { ...state, loadingbtn: false }
        default:
            return state;
    }
}