import service from './service';
import { API_URL, API_URL_CLOUD } from '../config/config';
import {get} from './serviceNew'
import { getByAccessToken } from './serviceNew'

export const getNpwpCitiesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/city`);
};

export const saveTaxApi = data => {
  return service.postAccessToken(
    `${API_URL}taxinfo/`,
    data.body,
  );
};

export const getTaxApi = data => {
  return getByAccessToken(
      `${API_URL}taxinfo/?user_id=${data.body.user_id}&get_names`
  );
}