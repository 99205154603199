import {HomeOutlined} from '@ant-design/icons';
import accountIcon from '../assets/icons/ic-account.svg'
import batchIcon from '../assets/icons/ic-batch.svg'
import dashboardIcon from '../assets/icons/ic-dashboard.svg'
import paymentIcon from '../assets/icons/ic-payment.svg'
import rekeningIcon from '../assets/icons/ic-rekening.svg'
import listIcon from '../assets/icons/ic-list.svg'

export interface DropdownSubmenuRoot {
  dropdownKey?: string|undefined;
  dropdown?: DropdownChildRoot[]
}
export interface DropdownChildRoot{
  name: string;
  key?: string;
  callback?: any | void;
}
export interface SubMenusRoot extends DropdownSubmenuRoot {
  name: string;
  icon: typeof HomeOutlined | string ;
  path: string;
}

export const subMenus:SubMenusRoot[] =[
  {
    name: 'Dashboard',
    icon: dashboardIcon,
    path: '/partner/dashboard',
  },
  {
    name: 'Upload Pinjaman',
    icon: batchIcon ,
    path: '/partner/batch-instruments',
  },
  {
    name: 'Pengembalian',
    icon: paymentIcon ,
    path: '/partner/instruments-repayment-list',
  },
  {
    name: 'Daftar Pinjaman',
    icon: listIcon ,
    path: '/partner/loan',
    dropdownKey: 'listLoan',
    dropdown: [
      { name: 'Ringkasan', key: '/partner/loan/summary' },
      { name: 'Semua Pinjaman', key: '/partner/loan/instruments' },
      { name: 'Pinjaman Aktif', key: '/partner/loan/instruments-active' },
      { name: 'Pinjaman Terdanai', key: '/partner/loan/instruments-sold' },
      { name: 'Pinjaman Lunas', key: '/partner/loan/payoff' },
    ],
  },
  {
    name: 'Rekening',
    icon: rekeningIcon ,
    path: '/partner/account',
    dropdownKey: 'accountNumber',
    dropdown: [
      { name: 'Dana Saya', key: '/partner/account/balance' },
      { name: 'Cara Transfer', key: '/partner/account/topup' },
      { name: 'Penarikan', key: '/partner/account/withdrawals' },
    ],
  },
  {
    name: 'Settings',
    icon: accountIcon ,
    path: '/partner/partner-digital-agreement',
    dropdownKey: 'settings',
    dropdown: [{ name: 'Perjanjian Pinjaman', key: '/partner/partner-digital-agreement' }],
  },
];
