import {GET_RDL_AGREEMENT_REQUEST, GET_RDL_AGREEMENT_SUCCESS, GET_RDL_AGREEMENT_FAILED} from "../actions/navigation";

const initialState = {
    url:"",
    isFetching: false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_RDL_AGREEMENT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_RDL_AGREEMENT_SUCCESS:
            return {
                ...state,
                url: action.data,
                isFetching: false
            };
        case GET_RDL_AGREEMENT_FAILED:
            return {
                ...state,
                isFetching: false
            };
        default:
            return state;
    }
}