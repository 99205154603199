import service from './service';
import { API_URL_CLOUD } from '../config/config';

export const getProvincesApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/provinsi`);
};
export const getCitiesApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/kota?id_provinsi=`+data);
};
export const getDistrictsApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/kecamatan?id_kota=`+data);
};
export const getSubdistrictsApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/kelurahan?id_kecamatan=`+data);
};
