import { defaultActionCreator } from './actionCreator';

export const GET_BANKS_REQUEST = 'GET_BANKS_REQUEST';
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS';
export const GET_BANKS_FAILED = 'GET_BANKS_FAILED';
export const getBanksRequest = defaultActionCreator(GET_BANKS_REQUEST, 'data');
export const getBanksSuccess = defaultActionCreator(GET_BANKS_SUCCESS, 'data');
export const getBanksFailed = defaultActionCreator(GET_BANKS_FAILED, 'error');

export const SET_BANK_ID = 'SET_BANK_ID';
export const setBankId = defaultActionCreator(SET_BANK_ID, 'data');

export const GET_FREQUENCY_AVERAGE_INCOME_REQUEST = 'GET_FREQUENCY_AVERAGE_INCOME_REQUEST';
export const GET_FREQUENCY_AVERAGE_INCOME_SUCCESS = 'GET_FREQUENCY_AVERAGE_INCOME_SUCCESS';
export const GET_FREQUENCY_AVERAGE_INCOME_FAILED = 'GET_FREQUENCY_AVERAGE_INCOME_FAILED';
export const getFrequencyAverageIncomeRequest = defaultActionCreator(GET_FREQUENCY_AVERAGE_INCOME_REQUEST, 'data');
export const getFrequencyAverageIncomeSuccess = defaultActionCreator(GET_FREQUENCY_AVERAGE_INCOME_SUCCESS, 'data');
export const getFrequencyAverageIncomeFailed = defaultActionCreator(GET_FREQUENCY_AVERAGE_INCOME_FAILED, 'error');

export const GET_FREQUENCY_AVERAGE_OUTCOME_REQUEST = 'GET_FREQUENCY_AVERAGE_OUTCOME_REQUEST';
export const GET_FREQUENCY_AVERAGE_OUTCOME_SUCCESS = 'GET_FREQUENCY_AVERAGE_OUTCOME_SUCCESS';
export const GET_FREQUENCY_AVERAGE_OUTCOME_FAILED = 'GET_FREQUENCY_AVERAGE_OUTCOME_FAILED';
export const getFrequencyAverageOutcomeRequest = defaultActionCreator(GET_FREQUENCY_AVERAGE_OUTCOME_REQUEST, 'data');
export const getFrequencyAverageOutcomeSuccess = defaultActionCreator(GET_FREQUENCY_AVERAGE_OUTCOME_SUCCESS, 'data');
export const getFrequencyAverageOutcomeFailed = defaultActionCreator(GET_FREQUENCY_AVERAGE_OUTCOME_FAILED, 'error');

export const SET_AVERAGE = 'SET_AVERAGE';
export const setAverage = defaultActionCreator(SET_AVERAGE, 'data', 'name');

export const GET_SOURCE_OF_FUNDS_REQUEST = 'GET_SOURCE_OF_FUNDS_REQUEST';
export const GET_SOURCE_OF_FUNDS_SUCCESS = 'GET_SOURCE_OF_FUNDS_SUCCESS';
export const GET_SOURCE_OF_FUNDS_FAILED = 'GET_SOURCE_OF_FUNDS_FAILED';
export const getSourceOfFundsRequest = defaultActionCreator(GET_SOURCE_OF_FUNDS_REQUEST, 'data');
export const getSourceOfFundsSuccess = defaultActionCreator(GET_SOURCE_OF_FUNDS_SUCCESS, 'data');
export const getSourceOfFundsFailed = defaultActionCreator(GET_SOURCE_OF_FUNDS_FAILED, 'error');

export const SET_SOURCE_OF_FUNDS_IDS = 'SET_SOURCE_OF_FUNDS_IDS';
export const setSourceOfFundsIds = defaultActionCreator(SET_SOURCE_OF_FUNDS_IDS, 'data');

export const SET_DATA_FIELD_BANK = 'SET_DATA_FIELD_BANK';
export const setDataFieldBank = defaultActionCreator(SET_DATA_FIELD_BANK, 'data');

export const SAVE_BANK_REQUEST = 'SAVE_BANK_REQUEST';
export const SAVE_BANK_SUCCESS = 'SAVE_BANK_SUCCESS';
export const SAVE_BANK_FAILED = 'SAVE_BANK_FAILED';
export const saveBankRequest = defaultActionCreator(SAVE_BANK_REQUEST, 'data','item');
export const saveBankSuccess = defaultActionCreator(SAVE_BANK_SUCCESS, 'data');
export const saveBankFailed = defaultActionCreator(SAVE_BANK_FAILED, 'error');

export const GET_BANK_REQUEST = 'GET_BANK_REQUEST';
export const GET_BANK_SUCCESS = 'GET_BANK_SUCCESS';
export const GET_BANK_FAILED = 'GET_BANK_FAILED';
export const getBankRequest = defaultActionCreator(GET_BANK_REQUEST, 'data');

export const SET_BANK_DATA = 'SET_BANK_DATA';
export const setbankData = defaultActionCreator(SET_BANK_DATA, 'data');
