import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StepCounter from '../StepCounter';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import './HeaderNew.scss';
import MekarIcon from '../assets/icons/ic-mekar.svg';
import {
  DownOutlined,
  LeftOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import endpoint from '../../helpers/getEndpoints';
import BarIcon from '../assets/icons/ic-bar-burger.svg';
import LogoutIcon from '../assets/icons/ic-logout.svg';
import SubMenu from './SubMenu';
import { removeCookies } from '../../config/utils';
import { useDispatch } from 'react-redux';
import { loanDefaultState } from '../../actions/loan';

export type HeaderProps = {
  onBackLink: string;
  progress?: {
    step: number;
    total: number;
  };
  username?: string;
  submenu?: string | undefined;
  onClick?: any | void | undefined;
};

const HeaderNew: React.FC<HeaderProps> = ({
  onBackLink,
  progress,
  username,
  submenu,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [show, setState] = useState(false);
  const dispatch = useDispatch()
  const showSubmenu = () => {
    setState((prev) => !prev);
  };
  const logout = () => {
    Modal.info({
      className: 'mekar-modal',
      content: contentModal(),
      centered: true,
      okCancel: true,
      okText: 'Konfirmasi',
      cancelText: 'Batal',
      okButtonProps: {
        className: 'mekar-btn mob-100',
      },
      cancelButtonProps: {
        className: 'mekar-btn mob-100',
      },
      icon: null,
      width: '780px',
      onOk() {
        dispatch(loanDefaultState());
        removeCookies();
      },
      onCancel() {
        setVisible(false);
      },
      visible: isVisible,
    });
  };
  const contentModal = () => (
    <div className="mekar-modal-body mekar-text-center">
      <h3>Konfirmasi Keluar</h3>
      <p className="mekar-modal-title">Apakah Anda yakin ingin keluar?</p>
      <img src={LogoutIcon} alt="ic-logout" className="mt-4 mb-4"></img>
    </div>
  );
  const onClick = ({ key }: any, name: any) => {
    message.info(`Click on item ${key} ${name}`);
  };
  const users = (
    <Menu onClick={logout}>
      <Menu.Item key="1">
        <PoweroffOutlined /> Keluar
      </Menu.Item>
    </Menu>
  );
  let backLink = <></>;
  if (onBackLink != 'hidden') {
    backLink = (
      <Link to={onBackLink}>
        <LeftOutlined style={{ fontSize: '20px', color: '#566477' }} />
      </Link>
    );
  }
  return (
    <header className="HeaderNew">
      <div className="wrapper">
        <div
          className={
            submenu !== undefined
              ? 'HeaderNew-container header-submenu'
              : 'HeaderNew-container'
          }
        >
          <div className="left-outline-container">{backLink}</div>
          <div className="logo-container">
            <a href={endpoint.mekarBaseUrl}>
              <img src={MekarIcon} width="120px" />
            </a>
          </div>
          <div id="dropdown-position" className="user-select">
            {submenu !== undefined ? (
              <>
                <Dropdown
                  getPopupContainer={():any => document.getElementById("dropdown-position")}
                  overlay={users}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button
                    type="link"
                    className="ant-dropdown-link collapse-btn-user"
                    onClick={(e) => e.preventDefault()}
                  >
                    {username} <DownOutlined />
                  </Button>
                </Dropdown>
                <Button
                  onClick={showSubmenu}
                  className="collapse-btn-bar"
                  type="text"
                  icon={<img src={BarIcon} width="19px" alt="logo" />}
                />
              </>
            ) : (
              <Button className="user-select-btn">{username}</Button>
            )}
          </div>
          {progress && (
            <div className="counter-container">
              <StepCounter step={progress.step} total={progress.total} />
            </div>
          )}
        </div>
        {submenu === 'partner' ? (
          <SubMenu
            onClick={onClick}
            username={username}
            show={show}
            logout={logout}
          />
        ) : null}
      </div>
    </header>
  );
};

export default HeaderNew;
