import service from './service';
import { API_URL, API_URL_CLOUD } from '../config/config';
import {get} from './serviceNew'
import { getByAccessToken } from './serviceNew'

export const getReligionsApi = () => {
  return service.extGet(`${API_URL_CLOUD}/religion`);
};
export const getMaritalStatusesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/marital_status`);
};
export const getEducationLevelsApi = () => {
  return service.extGet(`${API_URL_CLOUD}/education_level`);
};
export const getInvestmentPurposesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/investment_purpose`);
};

export const savePersonalInfoApi = data => {
  return service.postAccessToken(
    `${API_URL}personalinfo/`,
    data.body,
  );
};

export const getPersonalInfoApi = (data) =>{
  return getByAccessToken(
    `${API_URL}personalinfo/?user_id=${data.body.user_id}&get_names&no_prefix_phone`
  );
}
