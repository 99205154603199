import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as instrumentsPartnerAction from '../actions/instrumentPartner';
import { 
  getInstrumentsPartnerApi,
} from '../requests/instrumentsPartner';

export function* getInstrumentsPartner() {
  try {
    const response = yield call(getInstrumentsPartnerApi); 
    let data=response.data
    yield put({
      type: instrumentsPartnerAction.GET_INSTRUMENTS_PARTNER_SUCCESS,
      data,
    });
  } catch (e) {    
    yield put({
      type: instrumentsPartnerAction.GET_INSTRUMENTS_PARTNER_FAILED,
      error: e,
    });
  }
}
export default all([
  takeLatest(instrumentsPartnerAction.GET_INSTRUMENTS_PARTNER_REQUEST, getInstrumentsPartner),
]);
