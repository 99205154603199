import { defaultActionCreator } from './actionCreator';

export const GET_PROVINCES_HOME_REQUEST = 'GET_PROVINCES_HOME_REQUEST'
export const GET_PROVINCES_HOME_SUCCESS = 'GET_PROVINCES_HOME_SUCCESS'
export const GET_PROVINCES_HOME_FAILED = 'GET_PROVINCES_HOME_FAILED'
export const getProvincesHomeRequest = defaultActionCreator(GET_PROVINCES_HOME_REQUEST, 'data')
export const getProvincesHomeSuccess = defaultActionCreator(GET_PROVINCES_HOME_SUCCESS, 'data')
export const getProvincesHomeFailed = defaultActionCreator(GET_PROVINCES_HOME_FAILED, 'error')

export const SET_PROVINCE_ID_HOME = 'SET_PROVINCE_ID_HOME';
export const setProvinceIdHome = defaultActionCreator(SET_PROVINCE_ID_HOME, 'data');

export const GET_CITIES_HOME_REQUEST = 'GET_CITIES_HOME_REQUEST'
export const GET_CITIES_HOME_SUCCESS = 'GET_CITIES_HOME_SUCCESS'
export const GET_CITIES_HOME_FAILED = 'GET_CITIES_HOME_FAILED'
export const getCitiesHomeRequest = defaultActionCreator(GET_CITIES_HOME_REQUEST, 'data')
export const getCitiesHomeSuccess = defaultActionCreator(GET_CITIES_HOME_SUCCESS, 'data')
export const getCitiesHomeFailed = defaultActionCreator(GET_CITIES_HOME_FAILED, 'error')

export const SET_CITY_ID_HOME = 'SET_CITY_ID_HOME';
export const setCityIdHome = defaultActionCreator(SET_CITY_ID_HOME, 'data');

export const GET_DISTRICTS_HOME_REQUEST = 'GET_DISTRICTS_HOME_REQUEST'
export const GET_DISTRICTS_HOME_SUCCESS = 'GET_DISTRICTS_HOME_SUCCESS'
export const GET_DISTRICTS_HOME_FAILED = 'GET_DISTRICTS_HOME_FAILED'
export const getDistrictsHomeRequest = defaultActionCreator(GET_DISTRICTS_HOME_REQUEST, 'data')
export const getDistrictsHomeSuccess = defaultActionCreator(GET_DISTRICTS_HOME_SUCCESS, 'data')
export const getDistrictsHomeFailed = defaultActionCreator(GET_DISTRICTS_HOME_FAILED, 'error')

export const SET_DISTRICT_ID_HOME = 'SET_DISTRICT_ID_HOME';
export const setDistrictIdHome = defaultActionCreator(SET_DISTRICT_ID_HOME, 'data');

export const GET_SUBDISTRICTS_HOME_REQUEST = 'GET_SUBDISTRICTS_HOME_REQUEST'
export const GET_SUBDISTRICTS_HOME_SUCCESS = 'GET_SUBDISTRICTS_HOME_SUCCESS'
export const GET_SUBDISTRICTS_HOME_FAILED = 'GET_SUBDISTRICTS_HOME_FAILED'
export const getSubdistrictsHomeRequest = defaultActionCreator(GET_SUBDISTRICTS_HOME_REQUEST, 'data')
export const getSubdistrictsHomeSuccess = defaultActionCreator(GET_SUBDISTRICTS_HOME_SUCCESS, 'data')
export const getSubdistrictsHomeFailed = defaultActionCreator(GET_SUBDISTRICTS_HOME_FAILED, 'error')

export const SET_SUBDISTRICT_ID_HOME = 'SET_SUBDISTRICT_ID_HOME';
export const setSubdistrictIdHome = defaultActionCreator(SET_SUBDISTRICT_ID_HOME, 'data');

export const SET_DATA_FIELD_HOME = 'SET_DATA_FIELD_HOME';
export const setDataFieldHome = defaultActionCreator(SET_DATA_FIELD_HOME, 'data');

export const COPY_SAME_KTP = 'COPY_SAME_KTP';
export const copySameKTP = defaultActionCreator(COPY_SAME_KTP, 'data');

export const GET_HOMESTATUSES_REQUEST = 'GET_HOMESTATUSES_REQUEST'
export const GET_HOMESTATUSES_SUCCESS = 'GET_HOMESTATUSES_SUCCESS'
export const GET_HOMESTATUSES_FAILED = 'GET_HOMESTATUSES_FAILED'
export const getHomestatusesRequest = defaultActionCreator(GET_HOMESTATUSES_REQUEST, 'data')
export const getHomestatusesSuccess = defaultActionCreator(GET_HOMESTATUSES_SUCCESS, 'data')
export const getHomestatusesFailed = defaultActionCreator(GET_HOMESTATUSES_FAILED, 'error')

export const SET_HOMESTATUS_ID = 'SET_HOMESTATUS_ID';
export const setHomestatusId = defaultActionCreator(SET_HOMESTATUS_ID, 'data');

export const SAVE_ADDRESSES_REQUEST = 'SAVE_ADDRESSES_REQUEST'
export const SAVE_ADDRESSES_SUCCESS = 'SAVE_ADDRESSES_SUCCESS'
export const SAVE_ADDRESSES_FAILED = 'SAVE_ADDRESSES_FAILED'
export const saveAddressesRequest = defaultActionCreator(SAVE_ADDRESSES_REQUEST, 'data','item')
export const saveAddressesSuccess = defaultActionCreator(SAVE_ADDRESSES_SUCCESS, 'data')
export const saveAddressesFailed = defaultActionCreator(SAVE_ADDRESSES_FAILED, 'error')

export const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST'
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS'
export const GET_ADDRESSES_FAILED = 'GET_ADDRESSES_FAILED'
export const getAddressesRequest = defaultActionCreator(GET_ADDRESSES_REQUEST,'data','from')

export const SET_ADDRESSES = 'SET_ADDRESSES_REQUEST'
export const setAddresses = defaultActionCreator(SET_ADDRESSES, 'data')