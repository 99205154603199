import service from './service';
import { API_URL, API_URL_CLOUD } from '../config/config';
import { getByAccessToken } from './serviceNew'

export const getBanksApi = () => {
  return service.extGet(`${API_URL_CLOUD}/bank`);
};

export const getFrequencyAverageApi = (path) => {
  return service.extGet(API_URL_CLOUD + path);
};

export const getSourceOfFundsApi = () => {
  return service.extGet(`${API_URL_CLOUD}/source_of_funds`);
};

export const saveBankApi = data => {
  return service.postAccessToken(
    `${API_URL}bankinfo/`,
    data.body,
  );
};

export const getBankApi = data => {
  return getByAccessToken(
    `${API_URL}bankinfo/?user_id=${data.body.user_id}&get_names`
  );
}