import { defaultActionCreator } from './actionCreator';

export const GET_SEKTOR_USAHA_REQUEST = 'GET_SEKTOR_USAHA_REQUEST'
export const GET_SEKTOR_USAHA_SUCCESS = 'GET_SEKTOR_USAHA_SUCCESS'
export const GET_SEKTOR_USAHA_FAILED = 'GET_SEKTOR_USAHA_FAILED'
export const getSektorUsahaRequest = defaultActionCreator(GET_SEKTOR_USAHA_REQUEST, 'data')
export const getSektorUsahaSuccess = defaultActionCreator(GET_SEKTOR_USAHA_SUCCESS, 'data')
export const getSektorUsahaFailed = defaultActionCreator(GET_SEKTOR_USAHA_FAILED, 'error')

export const GET_RATING_REQUEST = 'GET_RATING_REQUEST'
export const GET_RATING_SUCCESS = 'GET_RATING_SUCCESS'
export const GET_RATING_FAILED = 'GET_RATING_FAILED'
export const getRatingRequest = defaultActionCreator(GET_RATING_REQUEST, 'data')
export const getRatingSuccess = defaultActionCreator(GET_RATING_SUCCESS, 'data')
export const getRatingFailed = defaultActionCreator(GET_RATING_FAILED, 'error')
