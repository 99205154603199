import { defaultActionCreator } from './actionCreator';

export const GET_OCCUPATIONS_REQUEST = 'GET_OCCUPATIONS_REQUEST'
export const GET_OCCUPATIONS_SUCCESS = 'GET_OCCUPATIONS_SUCCESS'
export const GET_OCCUPATIONS_FAILED = 'GET_OCCUPATIONS_FAILED'
export const getOccupationsRequest = defaultActionCreator(GET_OCCUPATIONS_REQUEST, 'data')
export const getOccupationsSuccess = defaultActionCreator(GET_OCCUPATIONS_SUCCESS, 'data')
export const getOccupationsFailed = defaultActionCreator(GET_OCCUPATIONS_FAILED, 'error')

export const SET_OCCUPATION_ID = 'SET_OCCUPATION_ID';
export const setOccupationId = defaultActionCreator(SET_OCCUPATION_ID, 'data');

export const GET_COMPANY_INDUSTRY_REQUEST = 'GET_COMPANY_INDUSTRY_REQUEST'
export const GET_COMPANY_INDUSTRY_SUCCESS = 'GET_COMPANY_INDUSTRY_SUCCESS'
export const GET_COMPANY_INDUSTRY_FAILED = 'GET_COMPANY_INDUSTRY_FAILED'
export const getCompanyIndustryRequest = defaultActionCreator(GET_COMPANY_INDUSTRY_REQUEST, 'data')
export const getCompanyIndustrySuccess = defaultActionCreator(GET_COMPANY_INDUSTRY_SUCCESS, 'data')
export const getCompanyIndustryFailed = defaultActionCreator(GET_COMPANY_INDUSTRY_FAILED, 'error')

export const SET_COMPANY_INDUSTRY_ID = 'SET_COMPANY_INDUSTRY_ID';
export const setCompanyIndustryId = defaultActionCreator(SET_COMPANY_INDUSTRY_ID, 'data');

export const GET_WORK_EXPERIENCE_REQUEST = 'GET_WORK_EXPERIENCE_REQUEST'
export const GET_WORK_EXPERIENCE_SUCCESS = 'GET_WORK_EXPERIENCE_SUCCESS'
export const GET_WORK_EXPERIENCE_FAILED = 'GET_WORK_EXPERIENCE_FAILED'
export const getWorkExperienceRequest = defaultActionCreator(GET_WORK_EXPERIENCE_REQUEST, 'data')
export const getWorkExperienceSuccess = defaultActionCreator(GET_WORK_EXPERIENCE_SUCCESS, 'data')
export const getWorkExperienceFailed = defaultActionCreator(GET_WORK_EXPERIENCE_FAILED, 'error')

export const SET_WORK_EXPERIENCE_ID = 'SET_WORK_EXPERIENCE_ID';
export const setWorkExperienceId = defaultActionCreator(SET_WORK_EXPERIENCE_ID, 'data');

export const GET_AVERAGE_INCOME_REQUEST = 'GET_AVERAGE_INCOME_REQUEST'
export const GET_AVERAGE_INCOME_SUCCESS = 'GET_AVERAGE_INCOME_SUCCESS'
export const GET_AVERAGE_INCOME_FAILED = 'GET_AVERAGE_INCOME_FAILED'
export const getAverageIncomeRequest = defaultActionCreator(GET_AVERAGE_INCOME_REQUEST, 'data')
export const getAverageIncomeSuccess = defaultActionCreator(GET_AVERAGE_INCOME_SUCCESS, 'data')
export const getAverageIncomeFailed = defaultActionCreator(GET_AVERAGE_INCOME_FAILED, 'error')

export const SET_AVERAGE_INCOME_ID = 'SET_AVERAGE_INCOME_ID';
export const setAverageIncomeId = defaultActionCreator(SET_AVERAGE_INCOME_ID, 'data');


export const GET_AVERAGE_OUTCOME_REQUEST = 'GET_AVERAGE_OUTCOME_REQUEST'
export const GET_AVERAGE_OUTCOME_SUCCESS = 'GET_AVERAGE_OUTCOME_SUCCESS'
export const GET_AVERAGE_OUTCOME_FAILED = 'GET_AVERAGE_OUTCOME_FAILED'
export const getAverageOutcomeRequest = defaultActionCreator(GET_AVERAGE_OUTCOME_REQUEST, 'data')
export const getAverageOutcomeSuccess = defaultActionCreator(GET_AVERAGE_OUTCOME_SUCCESS, 'data')
export const getAverageOutcomeFailed = defaultActionCreator(GET_AVERAGE_OUTCOME_FAILED, 'error')

export const SET_AVERAGE_OUTCOME_ID = 'SET_AVERAGE_INCOME_PER_MONTH_ID';
export const setAverageOutcomeId = defaultActionCreator(SET_AVERAGE_OUTCOME_ID, 'data');

export const SET_DATA_FIELD_OCCUPATION = 'SET_DATA_FIELD_OCCUPATION';
export const setDataFieldOccupation = defaultActionCreator(SET_DATA_FIELD_OCCUPATION, 'data');

export const SAVE_OCCUPATION_INFO_REQUEST = 'SAVE_OCCUPATION_INFO_REQUEST'
export const SAVE_OCCUPATION_INFO_SUCCESS = 'SAVE_OCCUPATION_INFO_SUCCESS'
export const SAVE_OCCUPATION_INFO_FAILED = 'SAVE_OCCUPATION_INFO_FAILED'
export const saveOccupationRequest = defaultActionCreator(SAVE_OCCUPATION_INFO_REQUEST, 'data','item')
export const saveOccupationSuccess = defaultActionCreator(SAVE_OCCUPATION_INFO_SUCCESS, 'data')
export const saveOccupationFailed = defaultActionCreator(SAVE_OCCUPATION_INFO_FAILED, 'error')


export const GET_BUSINESS_INFO_REQUEST = 'GET_BUSINESS_INFO_REQUEST'
export const GET_BUSINESS_INFO_SUCCESS = 'GET_BUSINESS_INFO_SUCCESS'
export const GET_BUSINESS_INFO_FAILED = 'GET_BUSINESS_INFO_FAILED'
export const getBusinessRequest = defaultActionCreator(GET_BUSINESS_INFO_REQUEST, 'data')

export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO'
export const setBusiness = defaultActionCreator(SET_BUSINESS_INFO, 'data')
