import { defaultActionCreator } from "./actionCreator";

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILED = 'GET_DOCUMENT_FAILED';
export const getDocumentRequest = defaultActionCreator(GET_DOCUMENT_REQUEST, 'data','image');
export const getDocumentSuccess = defaultActionCreator(GET_DOCUMENT_SUCCESS, 'data');
export const getDocumentFailed = defaultActionCreator(GET_DOCUMENT_FAILED, 'error');

export const POST_DOCUMENT_REQUEST = 'POST_DOCUMENT_REQUEST';
export const POST_DOCUMENT_SUCCESS = 'POST_DOCUMENT_SUCCESS';
export const POST_DOCUMENT_FAILED = 'POST_DOCUMENT_FAILED';
export const postDocumentRequest = defaultActionCreator(POST_DOCUMENT_REQUEST, 'data','item');
export const postDocumentSuccess = defaultActionCreator(POST_DOCUMENT_SUCCESS, 'data');
export const postDocumentFailed = defaultActionCreator(POST_DOCUMENT_FAILED, 'error');

export const SET_STATE_DOCUMENT = 'SET_STATE_DOCUMENT'
export const setStateDocument = defaultActionCreator(SET_STATE_DOCUMENT, 'name', 'value');
export const SET_STATE_DATA_DOCUMENT = 'SET_STATE_DATA_DOCUMENT'
export const setStateDataDocument = defaultActionCreator(SET_STATE_DATA_DOCUMENT, 'name', 'value');
