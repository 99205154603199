import {
    GET_INSTRUMENTS_PARTNER_FAILED, GET_INSTRUMENTS_PARTNER_REQUEST,GET_INSTRUMENTS_PARTNER_SUCCESS
  } from '../actions/instrumentPartner';
  
  const initialState = {
    data:null,
    isDataDetailFetching: false,
    loading:false
  };
  
  export default function reducer(state = initialState, action) {
    switch (action.type) {    
        case GET_INSTRUMENTS_PARTNER_REQUEST:
            return { ...state, isDataFetching: true, data: null }
        case GET_INSTRUMENTS_PARTNER_SUCCESS:
            return { ...state, isDataFetching: false, data: action.data }
        case GET_INSTRUMENTS_PARTNER_FAILED:
            return { ...state, isDataFetching: false, data: null }
        default:
            return state
    }
  }
  