import {
  GET_PROVINCES_HOME_SUCCESS,
  SET_PROVINCE_ID_HOME,
  GET_CITIES_HOME_SUCCESS,
  SET_CITY_ID_HOME,
  GET_DISTRICTS_HOME_SUCCESS,
  SET_DISTRICT_ID_HOME,
  GET_SUBDISTRICTS_HOME_SUCCESS,
  SET_SUBDISTRICT_ID_HOME,
  SET_DATA_FIELD_HOME,
  COPY_SAME_KTP,
  GET_HOMESTATUSES_SUCCESS,
  SET_HOMESTATUS_ID,
  GET_ADDRESSES_SUCCESS, SET_ADDRESSES, SAVE_ADDRESSES_REQUEST, SAVE_ADDRESSES_SUCCESS, SAVE_ADDRESSES_FAILED
} from '../actions/addressHome';
import { checkNameWithCode, whenFieldSelectIsZero } from '../config/utils';

const initialState = {
  loading: false,
  provinces_home: [],
  cities_home: [],
  districts_home: [],
  subdistricts_home: [],
  homestatuses: [],
  dataHomeDefault: {},
  dataHome: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROVINCES_HOME_SUCCESS:
      return {
        ...state,
        provinces_home: action.data,
        dataHome : {
          ...state.dataHome,
          province_id_home: {
            ...state.dataHome.province_id_home,
            name : action.data.length ? checkNameWithCode(action.data, state.dataHome.province_id_home?.id) : undefined,
          },
        }         
      };
    case SET_PROVINCE_ID_HOME: //clear all next selected field and arrays    
      return {
        ...state,
        cities_home: [],
        districts_home: [],
        subdistricts_home: [],
        dataHome: Object.entries({ ...state.dataHome, province_id_home: action.data }).reduce((acc, [k, v]) => (['city_id_home', 'district_id_home', 'subdistrict_id_home'].includes(k) ? acc : { ...acc, [k]: v }), {})
      };
    case GET_CITIES_HOME_SUCCESS:
      return {
        ...state,
        cities_home: action.data,
        dataHome : {
          ...state.dataHome,
          city_id_home: {
            ...state.dataHome.city_id_home,
            name : action.data.length ? checkNameWithCode(action.data, state.dataHome.city_id_home?.id) : undefined,
          },
        }
      };
    case SET_CITY_ID_HOME:  //clear all next selected field and arrays
      return {
        ...state,
        districts_home: [],
        subdistricts_home: [],
        dataHome: Object.entries({ ...state.dataHome, city_id_home: action.data })
          .reduce((acc, [k, v]) => (['district_id_home', 'subdistrict_id_home'].includes(k) ? acc : { ...acc, [k]: v }), {})
      };
    case GET_DISTRICTS_HOME_SUCCESS:
      return {
        ...state,
        districts_home: action.data,
        dataHome : {
          ...state.dataHome,
          district_id_home: {
            ...state.dataHome.district_id_home,
            name : action.data.length ? checkNameWithCode(action.data, state.dataHome.district_id_home?.id) : undefined,
          },
        }
      };
    case SET_DISTRICT_ID_HOME:    //clear all next selected field and arrays        
      return {
        ...state,
        subdistricts_home: [],
        dataHome: Object.entries({ ...state.dataHome, district_id_home: action.data })
          .reduce((acc, [k, v]) => (k === 'subdistrict_id_home' ? acc : { ...acc, [k]: v }), {})
      };
    case GET_SUBDISTRICTS_HOME_SUCCESS:
      return {
        ...state,
        subdistricts_home: action.data,
        dataHome : {
          ...state.dataHome,
          subdistrict_id_home: {
            ...state.dataHome.subdistrict_id_home,
            name : action.data.length ? checkNameWithCode(action.data, state.dataHome.subdistrict_id_home?.id) : undefined,
          },
        }
      };
    case SET_SUBDISTRICT_ID_HOME:
      return {
        ...state,
        dataHome: {
          ...state.dataHome,
          subdistrict_id_home: action.data,
        }
      };
    case SET_DATA_FIELD_HOME:
      return {
        ...state,
        dataHome: {
          ...state.dataHome,
          [action.data.name]: action.data.value,
        }
      };
    case COPY_SAME_KTP:
      return {
        ...state,
        dataHome: {
          ...state.dataHome,
          // ...action.data,
          ...Object.entries(action.data)
            .reduce((acc, [k, v]) => ({ ...acc, [`${k}_home`]: v }), {})
        }
      };
    case GET_HOMESTATUSES_SUCCESS:
      return {
        ...state,
        homestatuses: action.data,
        dataHome : {
          ...state.dataHome,
          homestatus_id: {
            ...state.dataHome.homestatus_id,
            name : action.data.length ? checkNameWithCode(action.data, state.dataHome.homestatus_id?.id) : undefined,
          },
        }
      };
    case SET_HOMESTATUS_ID:
      return {
        ...state,
        dataHome: {
          ...state.dataHome,
          homestatus_id: action.data,
        }
      };
    case GET_ADDRESSES_SUCCESS:
      const dataHomeDefault = {
        ...state.dataHome,
        address_home: action.data.address_home,
        rt_home: action.data.rt_home,
        rw_home: action.data.rw_home,
        postal_code_home: action.data.postal_code_home,
        province_id_home: {
          ...state.province_id_home,
          id: action.data.province_id_home,
          name: state.provinces_home.length? checkNameWithCode(state.provinces_home, action.data.province_id_home) : undefined
        },
        city_id_home: {
          ...state.city_id_home,
          id: action.data.city_id_home,
          name: whenFieldSelectIsZero(action.data, "city_id_home", "city_home")
        },
        district_id_home: {
          ...state.district_id_home,
          id: action.data.district_id_home,
          name: whenFieldSelectIsZero(action.data, "district_id_home", "district_home")
        },
        subdistrict_id_home: {
          ...state.subdistrict_id_home,
          id: action.data.subdistrict_id_home,
          name: whenFieldSelectIsZero(action.data, "subdistrict_id_home", "subdistrict_home")
        },
        homestatus_id: {
          ...state.homestatus_id,
          id: action.data.homestatus_id,
          name: state.homestatuses.length? checkNameWithCode(state.homestatuses, action.data.homestatus_id) : undefined
        },
      }
      return {
        ...state,
        dataHomeDefault,
        dataHome: dataHomeDefault
      };
    case SET_ADDRESSES:
      return {
        ...state,
        dataHome: {
          ...state.dataHome,
          province_id_home: {
            ...state.province_id_home,
            id: action.data.provincesHomeTemp.code,
            name: action.data.provincesHomeTemp.name,
          },
          city_id_home: {
            ...state.city_id_home,
            id: action.data.citiesHomeTemp.code,
            name: action.data.citiesHomeTemp.name,
          },
          district_id_home: {
            ...state.district_id_home,
            id: action.data.districtsHomeTemp.code,
            name: action.data.districtsHomeTemp.name,
          },
          subdistrict_id_home: {
            ...state.subdistrict_id_home,
            id: action.data.subdistrictsHomeTemp.code,
            name: action.data.subdistrictsHomeTemp.name,
          },
          homestatus_id: {
            ...state.homestatus_id,
            id: action.data.homestatusesTemp.id,
            name: action.data.homestatusesTemp.name,
          },
        },
      };
    case SAVE_ADDRESSES_REQUEST:
      return { ...state, loading: true }
    case SAVE_ADDRESSES_SUCCESS:
      return { ...state, loading: false }
    case SAVE_ADDRESSES_FAILED:
      return { ...state, loading: false }
    case "NOT_LOADING":
      return { ...state, loading: false }
    default:
      return state;
  }
}


