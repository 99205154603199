import axios from 'axios';
import history from '../history';
import {removeLocalstorage} from '../config/utils'
import { API_URL } from '../config/config';

class Service {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;

    let token = localStorage.getItem('profile_token');
    this.token = token;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    console.log("Service error: ", error);
    if (error.response && error.response.status === 401) {
      removeLocalstorage()
    }
    return Promise.reject(error)
  }

  extGet(path, params, callback) {
    return this.service.get(path, {
      params,
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    );
  }

  extGetLoan(path, params, callback) {
    this.token = localStorage.getItem('access_token');
    return this.service.get(path, {
      params,
      headers: {
        'Authorization': 'Bearer ' + this.token ,
        'Mekar-Client': 'true',
      }
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    );
  }

  get(path, params, callback) {
      this.token = localStorage.getItem('profile_token');

    return this.service.get(path, {
      params,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    )
  }

  extGetAccess(path, params, callback) {
    this.token = localStorage.getItem('access_token');
    return this.service.get(path, {
      params,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    );
  }
  getBlob(path, params, callback) {
    this.token = localStorage.getItem('profile_token');
    return this.service.get(path, {
      params,
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    );
  }

  patch(path, payload, callback) {
    this.token = localStorage.getItem('profile_token');
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then(response => callback ? callback(response.status, response.data) : response);
  }

  put(put, payload, callback) {
    this.token = localStorage.getItem('profile_token');
    return this.service.request({
      method: 'PUT',
      url: put,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then(response => callback ? callback(response.status, response.data) : response);
  }

  postLoan(path, payload, callback) {
    this.token = localStorage.getItem('access_token');

    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + this.token ,
        'Mekar-Client': 'true',
      }
    }).then((response) => callback ? callback(response.status, response.data) : response);
  }

  putLoan(path, payload, callback) {
    this.token = localStorage.getItem('access_token');
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + this.token ,
        'Mekar-Client': 'true',
      }
    }).then((response) => callback ? callback(response.status, response.data) : response);
  }

  post(path, payload, callback) {
    this.token = localStorage.getItem('profile_token');
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then((response) => callback ? callback(response.status, response.data) : response);
  }

  postAccessToken(path, payload, callback) {
    this.token = localStorage.getItem('access_token');
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then((response) => callback ? callback(response.status, response.data) : response);
  }

  delete(path, payload, callback) {
    this.token = localStorage.getItem('profile_token');
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': `Bearer ` + this.token,
      }
    }).then((response) => callback ? callback(response.status, response.data) : response);
  }

  getPDFService(path, params, callback) {
    this.token = localStorage.getItem('access_token');
    return this.service.get(path, {
      params,
      headers: {
        'Authorization': 'Bearer ' + this.token ,
        'Mekar-Client': 'true',
      }
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    );
  }

}

export default new Service();
