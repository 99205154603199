import {
  GET_OCCUPATIONS_SUCCESS,
  GET_COMPANY_INDUSTRY_SUCCESS,
  GET_AVERAGE_INCOME_SUCCESS,
  GET_WORK_EXPERIENCE_SUCCESS,
  SET_DATA_FIELD_OCCUPATION,
  SET_OCCUPATION_ID,
  SET_COMPANY_INDUSTRY_ID,
  SET_WORK_EXPERIENCE_ID,
  SET_AVERAGE_INCOME_ID,
  SET_AVERAGE_OUTCOME_ID,
  GET_BUSINESS_INFO_SUCCESS,
  SET_BUSINESS_INFO,
  GET_AVERAGE_OUTCOME_SUCCESS,
  SAVE_OCCUPATION_INFO_FAILED, SAVE_OCCUPATION_INFO_REQUEST, SAVE_OCCUPATION_INFO_SUCCESS
} from '../actions/occupation';
import { whenFieldSelectIsZero } from '../config/utils';

const initialState = {
  loading: false,
  occupations: [],
  companyIndustries: [],
  workExperiences: [],
  averageIncomes: [],
  averageOutcome: [],
  dataDefault: {},
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OCCUPATIONS_SUCCESS:
      return {
        ...state,
        occupations: action.data,
      };
    case GET_COMPANY_INDUSTRY_SUCCESS:
      return {
        ...state,
        companyIndustries: action.data,
      };
    case GET_AVERAGE_INCOME_SUCCESS:
      return {
        ...state,
        averageIncomes: action.data,
      };
    case GET_AVERAGE_OUTCOME_SUCCESS:
      return {
        ...state,
        averageOutcome: action.data,
      };
    case GET_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        workExperiences: action.data,
      };
    case SET_DATA_FIELD_OCCUPATION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: action.data.value,
        },
      };
    case SET_OCCUPATION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          occupation_id: action.data,
        },
      };
    case SET_COMPANY_INDUSTRY_ID:
      return {
        ...state,
        data: {
          ...state.data,
          company_industry_id: action.data,
        },
      };
    case SET_WORK_EXPERIENCE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          work_experience_id: action.data,
        },
      };
    case SET_AVERAGE_INCOME_ID:
      return {
        ...state,
        data: {
          ...state.data,
          average_income_id: action.data,
        },
      };

    case SET_AVERAGE_OUTCOME_ID:
      return {
        ...state,
        data: {
          ...state.data,
          average_outcome_id: action.data,
        },
      };
    case GET_BUSINESS_INFO_SUCCESS:
      const dataDefault = {
        ...state.data,
        occupation_id: {
          id: action.data.occupation_id,
          name: whenFieldSelectIsZero(action.data, "occupation_id", "occupation"),
        },
        other_occupation : action.data.other_occupation,
        company_industry_id: {
          id: action.data.company_industry_id,
          name: action.data.company_industry_id === 0 ? undefined : action.data.company_industry,
        },
        other_company_industry : action.data.other_company_industry,
        job_position_id: action.data.job_position_id.toString(),
        other_job_position: action.data.other_job_position,
        company_name: action.data.company_name,
        company_address: action.data.company_address,
        work_experience_id: {
          id: action.data.work_experience_id,
          name: action.data.work_experience_id === 0 ? undefined : "",
          // name: action.data.work_experience_id === 0 ? undefined : action.data.work_experience,
        },
        average_income_id: {
          id: action.data.average_income_id,
          name: action.data.average_income_id === 0 ? undefined : action.data.average_income,
        },
        average_outcome_id: {
          id: action.data.average_outcome_id,
          name: action.data.average_outcome_id === 0 ? undefined : action.data.average_outcome,
        },
        other_income : action.data.other_income? action.data.other_income : undefined,
        other_income_company_industry_id: action.data.other_income_company_industry? action.data.other_income_company_industry.id : undefined,
        other_income_company_industry: action.data.other_income_company_industry?  action.data.other_income_company_industry.other : undefined,
        average_other_income : action.data.average_other_income? action.data.average_other_income : undefined,
      }
      return {
        ...state,
        dataDefault,
        data: dataDefault
      };
    case SET_BUSINESS_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          occupation_id: {
            id: action.data.occupationsTemp.id,
            name: action.data.occupationsTemp.name,
          },
          company_industry_id: {
            id: action.data.companyIndustriesTemp.id,
            name: action.data.companyIndustriesTemp.name,
          },
          work_experience_id: {
            id: action.data.workExperiencesTemp.id,
            name: action.data.workExperiencesTemp.name,
          },
          average_income_id: {
            id: action.data.averageIncomesTemp.id,
            name: action.data.averageIncomesTemp.name,
          },
          average_outcome_id: {
            id: action.data.averageOutcomeTemp.id,
            name: action.data.averageOutcomeTemp.name,
          }
        },
      };
    case SAVE_OCCUPATION_INFO_REQUEST:
      return { ...state, loading: true }
    case SAVE_OCCUPATION_INFO_SUCCESS:
      return { ...state, loading: false }
    case SAVE_OCCUPATION_INFO_FAILED:
      return { ...state, loading: false }
    case "NOT_LOADING":
      return { ...state, loading: false }
    default:
      return state;
  }
}
