import {all, call, put, takeLatest} from "redux-saga/effects";
import * as accountActions from "../actions/account";
import history from "../history";
import {getAccountInfo} from "../requests/account";

export function* getAccount(action) {
    try {
        const response = yield call(getAccountInfo, action.data);
        let data = response.data;
        yield put({
            type: accountActions.GET_ACCOUNT_REQUEST,
            data,
        });
    } catch (e) {
        yield put({
            type: accountActions.GET_ACCOUNT_REQUEST,
            error: e,
        });
        localStorage.removeItem('username');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('profile_token');
        localStorage.removeItem('lenderId');
        localStorage.removeItem('phone');
        localStorage.removeItem('emailOtp');
        history.push('/');
    }
}

export default all([
    takeLatest(accountActions.GET_ACCOUNT_REQUEST, getAccount),
]);