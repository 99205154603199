import {defaultActionCreator} from "./actionCreator";

export const GET_PROGRESS_REQUEST = 'GET_PROGRESS_REQUEST'
export const getProgressRequest = defaultActionCreator(GET_PROGRESS_REQUEST, 'data')
export const GET_PROGRESS_SUCCESS = 'GET_PROGRESS_SUCCESS'
export const getProgressSuccess = defaultActionCreator(GET_PROGRESS_SUCCESS, 'data')
export const GET_PROGRESS_FAILED = 'GET_PROGRESS_FAILED'
export const getProgressFailed = defaultActionCreator(GET_PROGRESS_FAILED, 'data')


export const POST_PROFILE_COMPLETED_REQUEST = 'POST_PROFILE_COMPLETED_REQUEST'
export const postProfileCOmpletedRequest = defaultActionCreator(POST_PROFILE_COMPLETED_REQUEST, 'data')
export const POST_PROFILE_COMPLETED_SUCCESS = 'POST_PROFILE_COMPLETED_SUCCESS'
export const postProfileCOmpletedSuccess = defaultActionCreator(POST_PROFILE_COMPLETED_SUCCESS, 'data')
export const POST_PROFILE_COMPLETED_FAILED = 'POST_PROFILE_COMPLETED_FAILED'
export const postProfileCOmpletedFailed = defaultActionCreator(POST_PROFILE_COMPLETED_FAILED, 'data')

export const GET_RDL_AGREEMENT_REQUEST = 'GET_RDL_AGREEMENT_REQUEST'
export const getRDLAgreementRequest = defaultActionCreator(GET_RDL_AGREEMENT_REQUEST, 'data')
export const GET_RDL_AGREEMENT_SUCCESS = 'GET_RDL_AGREEMENT_SUCCESS'
export const getRDLAgreementSuccess = defaultActionCreator(GET_RDL_AGREEMENT_SUCCESS, 'data')
export const GET_RDL_AGREEMENT_FAILED = 'GET_RDL_AGREEMENT_FAILED'
export const getRDLAgreementFailed = defaultActionCreator(GET_RDL_AGREEMENT_FAILED, 'data')
