import { API_URL, API_PDF_SERVICE } from "../config/config";
import { getEndpoints } from "../helpers";
import service from './service'

export const getProgressApi = (data) => {
    return service.extGetAccess(
      `${API_URL}progress/?user_id=${data.body.user_id}`
    );
  }
  
export const postProfileCompletedApi =()=>{
  return service.postAccessToken(
    getEndpoints.accountServiceUrl + "api/profile/complete/"
  );
}

export const getRDLAgreementApi = () => {
  return service.getPDFService(
    `${API_PDF_SERVICE}v1/agreement/rdl-agreement`
  );
}

export const postProfileUpdateApi =(data)=>{
  return service.postAccessToken(
    getEndpoints.accountServiceUrl + "api/profile/update/",
    data,
  );
}