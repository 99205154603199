import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined, PoweroffOutlined } from '@ant-design/icons';
import {
  DropdownChildRoot,
  DropdownSubmenuRoot,
  subMenus,
  SubMenusRoot,
} from './SubMenus';
import history from '../../history';
import { useLocation } from 'react-router-dom';

type SubMenuProps = {
  show: boolean;
  username?: string;
  onClick?: any | void | undefined;
  logout: any | void;
};

const SubMenu: React.FC<SubMenuProps> = ({
  onClick,
  username,
  show,
  logout,
}) => {
  const loc = useLocation();
  const pathName = loc.pathname;

  const users = (
    <Menu onClick={logout}>
      <Menu.Item key="1">
        <PoweroffOutlined /> Keluar
      </Menu.Item>
    </Menu>
  );

  const menu = (item: DropdownSubmenuRoot) => (
    <Menu onClick={(ev) => history.push(`${ev.key}`)}>
      {item.dropdown?.map((item: DropdownChildRoot) => (
        <Menu.Item key={item.key}>{item.name}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div
      className={show ? 'content-menu-collapse show' : 'content-menu-collapse'}
    >
      <ul className="menu-collapse">
        <li id="dropdown-position" className="collapse-item user-item" >
          <Dropdown 
          getPopupContainer={():any => document.getElementById("dropdown-position")}
          overlay={users} placement="bottomRight" trigger={['click']}>
            <Button
              type="link"
              className="collapse-btn collapse-btn-user-slide"
              onClick={(e) => e.preventDefault()}
            >
              {username} <DownOutlined />
            </Button>
          </Dropdown>
        </li>
        {subMenus.map((item: SubMenusRoot, key) =>
          item.dropdownKey ? (
            <li id="dropdown-position" className="collapse-item" key={key}>
              <Dropdown 
                getPopupContainer={():any => document.getElementById("dropdown-position")}
                overlay={menu({
                  dropdownKey: item.dropdownKey,
                  dropdown: item.dropdown,
                })}
                trigger={['click']}
              >
                <Button 
                  type="link"
                  icon={typeof item.icon ==="string" ?<img className="ic-mekar" src={item.icon} alt="icon"/>:<item.icon />}
                  className={
                    pathName.includes(item.path)
                      ? 'ant-dropdown-link collapse-btn active'
                      : 'ant-dropdown-link collapse-btn'
                  }
                  onClick={(e) => e.preventDefault()}
                >
                  {item.name} <DownOutlined />
                </Button>
              </Dropdown>
            </li>
          ) : (
            <li className="collapse-item" key={key}>
              <Button
                onClick={() => history.push(item.path)}
                className={
                  pathName.includes(item.path)
                    ? 'collapse-btn active'
                    : 'collapse-btn'
                }
                type="link"
                icon={typeof item.icon ==="string" ?<img className="ic-mekar" src={item.icon} alt="icon"/>:<item.icon />}
              >
                {item.name}
              </Button>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default SubMenu;
