import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as personal1Actions from '../actions/personal1';
import {
  getBirthplaceCitiesApi,
} from '../requests/personal1';

export function* getBirthplaceCities(action) {
  try {
    const response = yield call(getBirthplaceCitiesApi, action.data);
    let data = response.data.city;
    yield put({
      type: personal1Actions.GET_BIRTHPLACE_CITIES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: personal1Actions.GET_BIRTHPLACE_CITIES_FAILED,
      error: e,
    });
  }
}

export default all([
  takeLatest(personal1Actions.GET_BIRTHPLACE_CITIES_REQUEST, getBirthplaceCities),

]);

