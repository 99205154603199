import { all, call, put, takeLatest } from "redux-saga/effects";
import * as loanActions from '../actions/loan'
import history from "../history";
import { getLoans, getLoanDetailById, postSubmitLoan, validationLoan, revisionLoan, getLoansFromAdmin, approvedLoanApi, rejectedLoanApi, getLoanFolderApi, approvedLoanBypassApi, getLoanFolderByUserIdApi, getLoanFolderByPartnerIdApi } from "../requests/loan";
import {WarningNotificationLoanFileError} from '../config/notificationPopUp'

export function* RetrieveLoanAll(action) {
    try {
        const response = yield call(getLoans, action.data);
        let data = response.data;
        if(data?.FileError){
            WarningNotificationLoanFileError(data.FileError)
        }
        yield put({
            type: loanActions.GET_LOAN_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: loanActions.GET_LOAN_FAILED,
            error: e,
        });
    }
}

export function* RetrieveLoanAllFromAdmin(action) {
    try {
        const response = yield call(getLoansFromAdmin, action.data);
        let data = response.data;
        if(data?.FileError){
            WarningNotificationLoanFileError(data.FileError)
        }
        yield put({
            type: loanActions.GET_LOAN_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: loanActions.GET_LOAN_FAILED,
            error: e,
        });
    }
}

export function* RetrieveLoanDetailById(action) {
    try {
        const response = yield call(getLoanDetailById, action.id);
        let data = response.data;
        yield put({
            type: loanActions.GET_LOAN_DETAIL_BY_ID_SUCCESS,
            data,
        });
    } catch (e) {
        yield action.modal()
        yield put({
            type: loanActions.GET_LOAN_DETAIL_BY_ID_FAILED,
            error: e,
        });
    }
}

export function* validationLoans(action) {
    try {
        const response = yield call(validationLoan, action.fileId, action.userId, action.status, action.partnerName, action.skip, action.role);
        let data = response.data;
        yield put({
            type: loanActions.VALIDATION_LOAN_SUCCESS,
            data,
        });
        const newFileName = data.file_name.split(' - ');
        history.push({ pathname: action.location.pathname, state: { ...action.location.state, file_name: newFileName.slice(0, (action.role === "ADMIN" && action.skip === false ? 5 : 3)).join(' - ') } });
    } catch (e) {
        yield action.modal()
        yield put({
            type: loanActions.VALIDATION_LOAN_FAILED,
            error: e,
        });
    }
}


export function* submitLoan(action) {
    try {
        const response = yield call(validationLoan, action.fileId, action.userId, action.status, action.partnerName, action.skip, action.role);
        let data = response.data;
        yield put({
            type: loanActions.VALIDATION_LOAN_SUCCESS,
            data,
        });
        const newFileName = data.file_name.split(' - ');
        history.push({ pathname: action.locationValid.pathname, state: { ...action.locationValid.state, file_name: newFileName.slice(0, (action.role === "ADMIN" && action.skip === false ? 5 : 3)).join(' - ') } });
        const dataSuc = data.data?.filter(
            (value) => value.keterangan != null
        ).length
        if (!dataSuc) {
            const dataNew = {
                user_id: Number(action.userId),
                file_id: action.data.file_id,
                file_name: action.data.file_name,
                date: new Date(data.date_loan).toISOString(),
                total_loan: data.total_loan,
                amount_loan: data.amount_loan,
                status: 1,
                loan_proposal_detail: data.data,
            };
            try {
                yield call(postSubmitLoan, dataNew);
                yield action.modal()
                yield put({
                    type: loanActions.POST_SUBMIT_LOAN_SUCCESS,
                });

            } catch (e) {
                yield put({
                    type: loanActions.POST_SUBMIT_LOAN_FAILED,
                    error: e,
                });
            }
        } else {
            yield put({
                type: loanActions.POST_SUBMIT_LOAN_FAILED,
            });
        }
    } catch (e) {
        yield action.modalValid()
        yield put({
            type: loanActions.VALIDATION_LOAN_FAILED,
            error: e,
        });
    }
}

export function* postRevisionLoan(action) {
    try {
        const response = yield call(validationLoan, action.fileId, action.userId, action.status, action.partnerName, action.skip, action.role);
        let data = response.data;
        yield put({
            type: loanActions.VALIDATION_LOAN_SUCCESS,
            data,
        });
        const newFileName = data.file_name.split(' - ');
        history.push({ pathname: action.locationValid.pathname, state: { ...action.locationValid.state, file_name: newFileName.slice(0, (action.role === "ADMIN" && action.skip === false ? 5 : 3)).join(' - ') } });

        const dataSuc = data.data?.filter(
            (value) => value.keterangan != null
        ).length
        if (!dataSuc) {
            const dataNew = {
                user_id: Number(action.userId),
                file_id: action.data.file_id,
                file_name: action.data.file_name,
                date: new Date(data.date_loan).toISOString(),
                total_loan: data.total_loan,
                amount_loan: data.amount_loan,
                status: 1,
                loan_proposal_detail: data.data,
            };
            try {
                yield call(revisionLoan, dataNew, action.id);
                yield action.modal()
                yield put({
                    type: loanActions.POST_REVISION_LOAN_SUCCESS,
                });
            } catch (e) {
                yield put({
                    type: loanActions.POST_REVISION_LOAN_FAILED,
                    error: e,
                });
            }
        } else {
            yield put({
                type: loanActions.POST_REVISION_LOAN_FAILED,
            });
        }
    } catch (e) {
        yield action.modalValid()
        yield put({
            type: loanActions.VALIDATION_LOAN_FAILED,
            error: e,
        });
    }
}

export function* approvedLoan(action) {
    try {
        const response = yield call(validationLoan, action.fileIdRisk, action.userId, action.status, action.partnerName, action.skip, action.role);
        let data = response.data;
        yield put({
            type: loanActions.VALIDATION_LOAN_SUCCESS,
            data,
        });
        const newFileName = data.file_name.split(' - ');
        history.push({ pathname: action.locationValid.pathname, state: { ...action.locationValid.state, file_name: newFileName.slice(0, (action.role === "ADMIN" && action.skip === false ? 5 : 3)).join(' - ') } });

        const dataSuc = data.data?.filter(
            (value) => value.keterangan != null
        ).length
        if (!dataSuc) {
            const dataNew = {
                user_id: Number(action.data.user_id),
                file_id: action.data.file_id,
                file_name: action.data.file_name,
                date: new Date(data.date_loan).toISOString(),
                total_loan: data.total_loan,
                amount_loan: data.amount_loan,
                status: 1,
                loan_proposal_detail: data.data,
            };
            try {
                yield call(approvedLoanApi, action.fileId, action.fileIdRisk, dataNew,action.emailOtp);
                yield put({
                    type: loanActions.POST_SUBMIT_LOAN_SUCCESS,
                });
                history.push(action.location);
            } catch (e) {
                yield put({
                    type: loanActions.POST_SUBMIT_LOAN_FAILED,
                    error: e,
                });
            }
        } else {
            yield put({
                type: loanActions.POST_SUBMIT_LOAN_FAILED,
            });
        }
    } catch (e) {
        yield action.modalValid()
        yield put({
            type: loanActions.VALIDATION_LOAN_FAILED,
            error: e,
        });
    }
}
export function* approvedLoanBypass(action) {
    try {
        const response = yield call(validationLoan, action.data.file_id, action.userId, action.status, action.partnerName, action.skip, action.role);
        let data = response.data;
        yield put({
            type: loanActions.VALIDATION_LOAN_SUCCESS,
            data,
        });
        const newFileName = data.file_name.split(' - ');
        history.push({ pathname: action.locationValid.pathname, state: { ...action.locationValid.state, file_name: newFileName.slice(0, (action.role === "ADMIN" && action.skip === false ? 5 : 3)).join(' - ') } });
        const dataSuc = data.data?.filter(
            (value) => value.keterangan != null
        ).length
        if (!dataSuc) {
            const dataNew = {
                user_id: Number(action.data.user_id) || 0,
                file_id: action.data.file_id,
                file_name: action.data.file_name,
                date: new Date(data.date_loan).toISOString(),
                total_loan: data.total_loan,
                amount_loan: data.amount_loan,
                status: 1,
                loan_proposal_detail: data.data,
                partner_id: action.data.partner_id,
            };
            try {
                yield call(approvedLoanBypassApi, dataNew,action.emailOtp);
                yield put({
                    type: loanActions.POST_SUBMIT_LOAN_SUCCESS,
                });
                history.push(action.location);
            } catch (e) {
                yield put({
                    type: loanActions.POST_SUBMIT_LOAN_FAILED,
                    error: e,
                });
            }
        } else {
            yield put({
                type: loanActions.POST_SUBMIT_LOAN_FAILED,
            });
        }
    } catch (e) {
        yield action.modalValid()
        yield put({
            type: loanActions.VALIDATION_LOAN_FAILED,
            error: e,
        });
    }
}

export function* rejectedLoan(action) {
    try {
        const response = yield call(validationLoan, action.fileIdRisk, action.userId, action.status, action.partnerName, action.skip, action.role);
        let data = response.data;
        yield put({
            type: loanActions.VALIDATION_LOAN_SUCCESS,
            data,
        });
        const newFileName = data.file_name.split(' - ');
        history.push({ pathname: action.locationValid.pathname, state: { ...action.locationValid.state, file_name: newFileName.slice(0, (action.role === "ADMIN" && action.skip === false ? 5 : 3)).join(' - ') } });
        const dataNew = {
            user_id: Number(action.data.user_id),
            file_id: action.data.file_id,
            file_name: action.data.file_name,
            date: new Date(data.date_loan).toISOString(),
            total_loan: data.total_loan,
            amount_loan: data.amount_loan,
            status: 1,
            loan_proposal_detail: data.data,
        };
       
        try {
            yield call(rejectedLoanApi, action.id, action.fileIdRisk, dataNew,action.emailOtp);
            yield put({
                type: loanActions.POST_SUBMIT_LOAN_SUCCESS,
            });
            history.push(action.location);
        } catch (e) {
            yield put({
                type: loanActions.POST_SUBMIT_LOAN_FAILED,
                error: e,
            });
        }

    } catch (e) {
        yield action.modalValid()
        yield put({
            type: loanActions.VALIDATION_LOAN_FAILED,
            error: e,
        });
    }

}

export function* retrieveLoanFolder() {
    try {
        const response = yield call(getLoanFolderApi);
        let data = response.data;
        yield put({
            type: loanActions.GET_LOAN_FOLDER_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: loanActions.GET_LOAN_FOLDER_FAILED,
            error: e,
        });
    }
}

export function* retrieveLoanFolderById(action) {
    try {
        const response = yield call(getLoanFolderByUserIdApi, action.userId);
        let data = response.data;
        yield put({
            type: loanActions.GET_LOAN_FOLDER_BY_USER_ID_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: loanActions.GET_LOAN_FOLDER_BY_USER_ID_FAILED,
            error: e,
        });
    }
}

export function* retrieveLoanFolderByPartnerId(action) {
    try {
        const response = yield call(getLoanFolderByPartnerIdApi, action.partnerId);
        let data = response.data;
        yield put({
            type: loanActions.GET_LOAN_FOLDER_BY_USER_ID_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: loanActions.GET_LOAN_FOLDER_BY_USER_ID_FAILED,
            error: e,
        });
    }
}


export default all([
    takeLatest(loanActions.GET_LOAN_REQUEST, RetrieveLoanAll),
    takeLatest(loanActions.GET_LOAN_FROM_ADMIN_REQUEST, RetrieveLoanAllFromAdmin),
    takeLatest(loanActions.GET_LOAN_DETAIL_BY_ID_REQUEST, RetrieveLoanDetailById),
    takeLatest(loanActions.POST_SUBMIT_LOAN_REQUEST, submitLoan),
    takeLatest(loanActions.VALIDATION_LOAN_REQUEST, validationLoans),
    takeLatest(loanActions.POST_REVISION_LOAN_REQUEST, postRevisionLoan),
    takeLatest(loanActions.POST_APPROVED_LOAN_REQUEST, approvedLoan),
    takeLatest(loanActions.POST_APPROVED_LOAN_BYPASS_REQUEST, approvedLoanBypass),
    takeLatest(loanActions.PUT_REJECTED_LOAN_REQUEST, rejectedLoan),
    takeLatest(loanActions.GET_LOAN_FOLDER_REQUEST, retrieveLoanFolder),
    takeLatest(loanActions.GET_LOAN_FOLDER_BY_USER_ID_REQUEST, retrieveLoanFolderById),
    takeLatest(loanActions.GET_LOAN_FOLDER_BY_PARTNER_ID_REQUEST, retrieveLoanFolderByPartnerId),

]);

