import service from './service';
import { API_URL, API_URL_CLOUD } from '../config/config';
import {get} from './serviceNew'
import { getByAccessToken } from './serviceNew'

export const getProvincesApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/provinsi`);
};
export const getCitiesApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/kota?id_provinsi=`+data);
};
export const getDistrictsApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/kecamatan?id_kota=`+data);
};
export const getSubdistrictsApi = (data) => {
  return service.extGet(`${API_URL_CLOUD}/kelurahan?id_kecamatan=`+data);
};
export const getHomestatusesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/home_status`);
};

export const saveAddressesApi = data => {
  return service.postAccessToken(
    `${API_URL}address/`,
    data.body,
  );
};

export const getAddressApi = (data) => {
  return getByAccessToken(
    `${API_URL}address/?user_id=${data.body.user_id}` 
  );
}
