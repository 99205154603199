import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import general from './general';
import document from './document';
import address from './address';
import addressHome from './addressHome';
import bank from './bank';
import occupation from './occupation';
import personal1 from './personal1';
import personal2 from './personal2';
import tax from './tax';
import thankyounew from './thankyounew';
import account from "./account";
import credential from "./credential";
import navigation from "./navigation";
import loan from './loan';
import loanFolder from './loanFolder';
import utility from './utility';
import instrumentsPartner from './instrumentsPartner';
import pdf from './pdf';


const createRootReducer = (history) => combineReducers({
    credential,
    account,
    general,
    document,
    address,
    addressHome,
    bank,
    occupation,
    personal1,
    personal2,
    tax,
    loanFolder,
    loan,
    instrumentsPartner,
    utility,
    thankyounew,
    navigation,
    router: connectRouter(history),
    pdf,
});

export default createRootReducer;