import {GET_ACCOUNT_REQUEST} from "../actions/account";

const initialState = {
    data:{}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNT_REQUEST:
            return {
                ...state,
                data: action.data,
            };
        default:
            return state;
    }
}