import React, { ReactChild } from "react";
import './DashboardLayout.scss';

type Props = {
  className?: string,
  children: ReactChild;
};

const DashboardLayout: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={`DashboardLayout wrapper ${className}`}>
      {children}
    </div>
  );
};

export default DashboardLayout;
