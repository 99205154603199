import service from './service';
import { API_URL } from '../config/config';


export const getDocumentApi = (data) => {
  return service.extGetAccess(API_URL +"document/?user_id="+data.body.user_id);
};
export const postDocumentApi = (data) => {
  return service.postAccessToken(API_URL +"document/",data);
};
