import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as generalActions from '../actions/general';
import { 
  getAddressConfigApi,
} from '../requests/general';

export function* getAddressConfig() {
  try {
    const response = yield call(getAddressConfigApi); 
    let data=response.data.item.reduce((prev, next)=>({
        ...prev,
        [next.name]:next.item[0].request,
      }),{}
    )
    yield put({
      type: generalActions.GET_ADDRESS_CONFIG_SUCCESS,
      data,
    });
  } catch (e) {    
    yield put({
      type: generalActions.GET_ADDRESS_CONFIG_FAILED,
      error: e,
    });
  }
}
export default all([
  takeLatest(generalActions.GET_ADDRESS_CONFIG_REQUEST, getAddressConfig),
]);
