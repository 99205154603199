import { GET_LOAN_FOLDER_BY_PARTNER_ID_REQUEST, GET_LOAN_FOLDER_BY_USER_ID_FAILED, GET_LOAN_FOLDER_BY_USER_ID_REQUEST, GET_LOAN_FOLDER_BY_USER_ID_SUCCESS, GET_LOAN_FOLDER_FAILED, GET_LOAN_FOLDER_REQUEST, GET_LOAN_FOLDER_SUCCESS } from "../actions/loan";

const initialState = {
    isError: null,
    isDataFetching: false,
    data: null,
    dataById: null
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_LOAN_FOLDER_REQUEST:
            return { ...state, isDataFetching: true, data: null }
        case GET_LOAN_FOLDER_SUCCESS:
            return { ...state, isDataFetching: false, data: action.data }
        case GET_LOAN_FOLDER_FAILED:
            return { ...state, isDataFetching: false, data: null }
        case GET_LOAN_FOLDER_BY_PARTNER_ID_REQUEST:
            return { ...state, isDataFetching: true, dataById: null }
        case GET_LOAN_FOLDER_BY_USER_ID_REQUEST:
            return { ...state, isDataFetching: true, dataById: null }
        case GET_LOAN_FOLDER_BY_USER_ID_SUCCESS:
            return { ...state, isDataFetching: false, dataById: action.data }
        case GET_LOAN_FOLDER_BY_USER_ID_FAILED:
            return { ...state, isDataFetching: false, dataById: null }
        default:
            return state
    }
}