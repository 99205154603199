import { defaultActionCreator } from './actionCreator';

export const GET_BIRTHPLACE_CITIES_REQUEST = 'GET_BIRTHPLACE_CITIES_REQUEST'
export const GET_BIRTHPLACE_CITIES_SUCCESS = 'GET_BIRTHPLACE_CITIES_SUCCESS'
export const GET_BIRTHPLACE_CITIES_FAILED = 'GET_BIRTHPLACE_CITIES_FAILED'
export const getBirthplaceCitiesRequest = defaultActionCreator(GET_BIRTHPLACE_CITIES_REQUEST, 'data')
export const getBirthplaceCitiesSuccess = defaultActionCreator(GET_BIRTHPLACE_CITIES_SUCCESS, 'data')
export const getBirthplaceCitiesFailed = defaultActionCreator(GET_BIRTHPLACE_CITIES_FAILED, 'error')

export const SET_DATA_FIELD_PERS1 = 'SET_DATA_FIELD_PERS1';
export const setDataFieldPers1 = defaultActionCreator(SET_DATA_FIELD_PERS1, 'data');

export const SET_BIRTHPLACE_CITY_ID = 'SET_BIRTHPLACE_CITY_ID';
export const setBirthplaceCityId = defaultActionCreator(SET_BIRTHPLACE_CITY_ID, 'data');

export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO'
export const setPersonalInfo = defaultActionCreator(SET_PERSONAL_INFO,'data')

export const SET_BIRTHPLACE = 'SET_BIRTHPLACE'
export const setBirthplace = defaultActionCreator(SET_BIRTHPLACE,'data')

