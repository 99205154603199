import { authLocalStorage } from "../config/utils";
import {
  routeProtect,
  routeProtectPartner,
  routeProtectStaffLoan,
  routes,
} from "./routes";

const ProtectedRoute = (): any => {
  let auth = authLocalStorage();
  console.log("SESSION_AGE :",process.env.REACT_APP_SESSION_MAX_AGE);
  
  if (auth != null) {
    if (auth.authInfo?.is_foreign_funder) {
      return routes;
    } else {
      if (auth.role === "PARTNER") {
        return [...routeProtectPartner, ...routes];
      } else if (auth.role === "RISK") {
        return [...routeProtectStaffLoan.risk, ...routes];
      } else if (auth.role === "SALES-LENDING") {
        return [...routeProtectStaffLoan.sales, ...routes];
      }
      return [...routeProtect, ...routes];
    }
  } else {
    return routes;
  }
};
export default ProtectedRoute;
