import {
  GET_BANKS_SUCCESS,
  SET_BANK_ID,
  GET_SOURCE_OF_FUNDS_SUCCESS,
  SET_SOURCE_OF_FUNDS_IDS,
  SET_DATA_FIELD_BANK,
  GET_BANK_SUCCESS,
  SAVE_BANK_SUCCESS, SAVE_BANK_REQUEST, SAVE_BANK_FAILED,
  GET_FREQUENCY_AVERAGE_INCOME_SUCCESS,
  GET_FREQUENCY_AVERAGE_OUTCOME_SUCCESS,
  SET_AVERAGE,
} from '../actions/bank';
import { checkNameWithCode } from '../config/utils';

const initialState = {
  loading: false,
  banks: [],
  sourceOfFunds: [],
  frequencyAverageIncome: [],
  frequencyAverageOutcame: [],
  dataDefault: {},
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BANKS_SUCCESS:

      return {
        ...state,
        banks: action.data,
      };
    case GET_FREQUENCY_AVERAGE_INCOME_SUCCESS:
      return {
        ...state,
        frequencyAverageIncome: action.data,
      };

    case GET_FREQUENCY_AVERAGE_OUTCOME_SUCCESS:
      return {
        ...state,
        frequencyAverageOutcame: action.data,
      };
    case SET_BANK_ID:
      return {
        ...state,
        data: {
          ...state.data,
          bank_id: action.data,
        },
      };
    case SET_AVERAGE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.data,
        },
      };
    case GET_SOURCE_OF_FUNDS_SUCCESS:
      return {
        ...state,
        sourceOfFunds: action.data,
      };
    case SET_SOURCE_OF_FUNDS_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          source_of_funds_ids: action.data,
        },
      };
    case SET_DATA_FIELD_BANK:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: action.data.value,
        },
      };
    case GET_BANK_SUCCESS:
      const dataDefault = {
        ...state.data,
        account_holder_name: action.data.account_holder_name,
        account_number: action.data.account_number,
        bank_id: {
          id: action.data.bank_id,
          name: action.data.bank_id && state.banks.length? checkNameWithCode(state.banks, action.data.bank_id) : undefined
        },
        other_source_of_funds: action.data.source_of_funds.other,
        source_of_funds: action.data.source_of_funds,
        income_frequency_id: {
          id: action.data.income_frequency_id,
          name: action.data.income_frequency_id && state.frequencyAverageIncome.length? checkNameWithCode(state.frequencyAverageIncome, action.data.income_frequency_id, true) : undefined
        },
        outcome_frequency_id: {
          id: action.data.outcome_frequency_id,
          name: action.data.outcome_frequency_id && state.frequencyAverageOutcame.length? checkNameWithCode(state.frequencyAverageOutcame, action.data.outcome_frequency_id, true) : undefined
        },
        is_beneficial_owner: action.data.is_beneficial_owner,
      }
      return {
        ...state,
        dataDefault,
        data: dataDefault,
      };
    case SAVE_BANK_SUCCESS:
      const defaultData = {
        ...state.data,
        other_source_of_funds: action.data.source_of_funds.other,
        source_of_funds: action.data.source_of_funds,
      }

      return {
        ...state,
        dataDefault: defaultData,
        data: defaultData,
        loading: false
      };
    case SAVE_BANK_REQUEST:
      return { ...state, loading: true }
    case SAVE_BANK_FAILED:
      return { ...state, loading: false }
    case "NOT_LOADING":
      return { ...state, loading: false }
    default:
      return state;
  }
}
