import {
  GET_RELIGIONS_SUCCESS,
  SET_RELIGION_ID,
  GET_MARITAL_STATUSES_SUCCESS,
  SET_MARITAL_STATUS_ID,
  GET_EDUCATION_LEVELS_SUCCESS,
  SET_EDUCATION_LEVEL_ID,
  GET_INVESTMENT_PURPOSES_SUCCESS,
  SET_INVESTMENT_PURPOSE_ID,
  GET_PERSONAL_INFO_SUCCESS,
  SET_PERSONAL2_INFO, SAVE_PERSONAL_INFO_REQUEST, SAVE_PERSONAL_INFO_SUCCESS, SAVE_PERSONAL_INFO_FAILED
} from '../actions/personal2';
import { checkNameWithCode, whenFieldSelectIsZero } from '../config/utils';

const initialState = {
  loading: false,
  religions: [],
  maritalStatuses: [],
  educationLevels: [],
  investmentPurposes: [],
  dataDefault: {},
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RELIGIONS_SUCCESS:
      return {
        ...state,
        religions: action.data,
        data : {
          ...state.data,
          religion_id : {
            ...state.data.religion_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.religion_id?.id) : undefined
          }
        }
      };
    case SET_RELIGION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          religion_id: action.data,
        }
      };
    case GET_MARITAL_STATUSES_SUCCESS:
      return {
        ...state,
        maritalStatuses: action.data,
        data : {
          ...state.data,
          marital_status_id : {
            ...state.data.marital_status_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.marital_status_id?.id) : undefined
          }
        }
      };
    case SET_MARITAL_STATUS_ID:
      return {
        ...state,
        data: {
          ...state.data,
          marital_status_id: action.data,
        }
      };
    case GET_EDUCATION_LEVELS_SUCCESS:
      return {
        ...state,
        educationLevels: action.data,
        data : {
          ...state.data,
          education_level_id : {
            ...state.data.education_level_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.education_level_id?.id) : undefined
          }
        }
      };
    case SET_EDUCATION_LEVEL_ID:
      return {
        ...state,
        data: {
          ...state.data,
          education_level_id: action.data,
        }
      };
    case GET_INVESTMENT_PURPOSES_SUCCESS:
      return {
        ...state,
        investmentPurposes: action.data,
      };
    case SET_INVESTMENT_PURPOSE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          investment_purpose_id: action.data,
        }
      };
    case GET_PERSONAL_INFO_SUCCESS:
      const dataDefault = {
        ...state.data,
        religion_id: {
          id: action.data.religion_id,
          name: action.data.religion_id !== 0 ? checkNameWithCode(state.religions, action.data.religion_id) : undefined
        },
        marital_status_id: {
          id: action.data.marital_status_id,
          name: action.data.marital_status_id !== 0 ? checkNameWithCode(state.maritalStatuses, action.data.marital_status_id) : undefined
        },
        education_level_id: {
          id: action.data.education_level_id,
          name: action.data.education_level_id !== 0 ? checkNameWithCode(state.educationLevels, action.data.education_level_id) : undefined
        },
        investment_purpose_id: {
          id: action.data.investment_purpose_id,
          name: whenFieldSelectIsZero(action.data, "investment_purpose_id", "investment_purpose"),
        },
      }
      return {
        ...state,
        dataDefault,
        data: dataDefault
      };
    case SET_PERSONAL2_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          religion_id: {
            id: action.data.religionsTemp.id,
            name: action.data.religionsTemp.name,
          },
          marital_status_id: {
            id: action.data.maritalStatusesTemp.id,
            name: action.data.maritalStatusesTemp.name,
          },
          education_level_id: {
            id: action.data.educationLevelsTemp.id,
            name: action.data.educationLevelsTemp.name,
          },
          investment_purpose_id: {
            id: action.data.investmentPurposesTemp.id,
            name: action.data.investmentPurposesTemp.name,
          },
        }
      };
    case SAVE_PERSONAL_INFO_REQUEST:
      return { ...state, loading: true }
    case SAVE_PERSONAL_INFO_SUCCESS:
      return { ...state, loading: false }
    case SAVE_PERSONAL_INFO_FAILED:
      return { ...state, loading: false }
    case "NOT_LOADING":
      return { ...state, loading: false }
    default:
      return state;
  }
}
