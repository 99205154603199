import { LOAN_DEFAULT_STATE, FILTER_PARTNER_CHANGE, GET_LOAN_DETAIL_BY_ID_FAILED, GET_LOAN_DETAIL_BY_ID_REQUEST, GET_LOAN_DETAIL_BY_ID_SUCCESS, GET_LOAN_FAILED, GET_LOAN_FROM_ADMIN_REQUEST, GET_LOAN_REQUEST, GET_LOAN_SUCCESS, POST_APPROVED_LOAN_BYPASS_REQUEST, POST_APPROVED_LOAN_REQUEST, POST_REVISION_LOAN_FAILED, POST_REVISION_LOAN_REQUEST, POST_REVISION_LOAN_SUCCESS, POST_SUBMIT_LOAN_FAILED, POST_SUBMIT_LOAN_REQUEST, POST_SUBMIT_LOAN_SUCCESS, PUT_REJECTED_LOAN_REQUEST, VALIDATION_LOAN_FAILED, VALIDATION_LOAN_REQUEST, VALIDATION_LOAN_SUCCESS, SORTER_FIELD_LOAN } from "../actions/loan";

const initialState = {
    dateLoan: null,
    totalLoan: null,
    amountLoan: null,
    loading: false,
    isError: null,
    isDataFetching: false,
    data: {},
    isDataDetailFetching: false,
    dataDetail: null,
    dataDetailCurrent: null,
    filterPartner: {}
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_LOAN_REQUEST:
            return { ...state, isDataFetching: true, data: {} }
        case GET_LOAN_FROM_ADMIN_REQUEST:
            return { ...state, isDataFetching: true, data: {} }
        case GET_LOAN_SUCCESS:
            const data = { ...action.data, Data: action.data.Data.map((obj, i) => ({ ...obj, key: i })) }
            return { ...state, isDataFetching: false, data }
        case GET_LOAN_FAILED:
            return { ...state, isDataFetching: false, data: {} }
        case GET_LOAN_DETAIL_BY_ID_REQUEST:
            return { ...state, isDataDetailFetching: true, dataDetail: null }
        case GET_LOAN_DETAIL_BY_ID_SUCCESS:
            const dataDetail = action.data.loan_proposal_detail.sort((a, b) => b.status - a.status)
            return {
                ...state, isDataDetailFetching: false, dataDetail,
                dataDetailCurrent: dataDetail, dateLoan: action.data.date,
                totalLoan: action.data.total_loan,
                amountLoan: action.data.amount_loan,
            }
        case GET_LOAN_DETAIL_BY_ID_FAILED:
            return { ...state, isDataDetailFetching: false, dataDetail: null }
        case VALIDATION_LOAN_REQUEST:
            return { ...state, isDataDetailFetching: true, dataDetail: null }
        case VALIDATION_LOAN_SUCCESS:
            const dataDetail2 = action.data.data.sort((a, b) => b.status - a.status)
            return {
                ...state, isDataDetailFetching: false, dataDetail: dataDetail2,
                dataDetailCurrent: dataDetail2, dateLoan: action.data.date_loan,
                totalLoan: action.data.total_loan,
                amountLoan: action.data.amount_loan,
            }
        case VALIDATION_LOAN_FAILED:
            return { ...state, isDataDetailFetching: false, dataDetail: null }
        case POST_SUBMIT_LOAN_REQUEST:
            return { ...state, loading: true, isDataDetailFetching: true, dataDetail: null }
        case POST_APPROVED_LOAN_REQUEST:
            return { ...state, loading: true, isDataDetailFetching: true, dataDetail: null }
        case POST_APPROVED_LOAN_BYPASS_REQUEST:
            return { ...state, loading: true, isDataDetailFetching: true, dataDetail: null }
        case PUT_REJECTED_LOAN_REQUEST:
            return { ...state, loading: true, isDataDetailFetching: true, dataDetail: null }
        case POST_SUBMIT_LOAN_SUCCESS:
            return { ...state, loading: false, isError: false }
        case POST_SUBMIT_LOAN_FAILED:
            return { ...state, loading: false, isError: true }
        case POST_REVISION_LOAN_REQUEST:
            return { ...state, loading: true, isDataDetailFetching: true, dataDetail: null }
        case POST_REVISION_LOAN_SUCCESS:
            return { ...state, loading: false, isError: false }
        case POST_REVISION_LOAN_FAILED:
            return { ...state, loading: false, isError: true }
        case FILTER_PARTNER_CHANGE:
            return { ...state, filterPartner: action.data }
        case SORTER_FIELD_LOAN:
            if (action.sorter === 'semua') {
                return { ...state, dataDetail: state.dataDetailCurrent }
            } else if (action.sorter === 'berhasil') {
                const berhasil = state.dataDetailCurrent.filter((item) => item.keterangan == null)
                return { ...state, dataDetail: berhasil }
            } else if (action.sorter === 'gagal') {
                const gagal = state.dataDetailCurrent.filter((item) => item.keterangan != null)
                return { ...state, dataDetail: gagal }
            }
        case LOAN_DEFAULT_STATE:
            return {
                dateLoan: null,
                totalLoan: null,
                amountLoan: null,
                loading: false,
                isError: null,
                isDataFetching: false,
                data: {},
                isDataDetailFetching: false,
                dataDetail: null,
                dataDetailCurrent: null,
                filterPartner: ""
            }
        default:
            return state
    }
}