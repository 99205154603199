import {
  GET_RATING_SUCCESS,GET_SEKTOR_USAHA_SUCCESS
} from '../actions/utility';

const initialState = {
  bisnisSektor : {
      data:{},
      loading:false
  },
  rating : {
      data:{},
      loading:false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SEKTOR_USAHA_SUCCESS:
        return {
          ...state,
          bisnisSektor: { ...state.bisnisSektor, data : action.data }
        };  
    case GET_RATING_SUCCESS:
        return {
          ...state,
          rating: { ...state.rating, data : action.data }
        };
    default:
      return state;
  }
}
