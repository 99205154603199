import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as addressHomeActions from '../actions/addressHome';
import * as addressActions from '../actions/address';

import {
  getProvincesApi,
  getCitiesApi,
  getDistrictsApi,
  getSubdistrictsApi,
  getHomestatusesApi,
  saveAddressesApi,
  getAddressApi,
} from '../requests/addressHome';
import history from '../history';
import {postProfileUpdateApi} from '../requests/navigation'
import {userInfoLocalStorage} from '../config/utils'
import {ADDRESS_INFO} from '../helpers/globalConst'


export function* getProvincesHome(action) {
  try {
    const response = yield call(getProvincesApi, action.data);
    let data = response.data.provinsi;
    yield put({
      type: addressHomeActions.GET_PROVINCES_HOME_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressHomeActions.GET_PROVINCES_HOME_FAILED,
      error: e,
    });
  }
}
export function* getCitiesHome(action) {
  try {
    const response = yield call(getCitiesApi, action.data);
    let data = response.data.kota;
    yield put({
      type: addressHomeActions.GET_CITIES_HOME_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressHomeActions.GET_CITIES_HOME_FAILED,
      error: e,
    });
  }
}
export function* getDistrictsHome(action) {
  try {
    const response = yield call(getDistrictsApi, action.data);
    let data = response.data.kecamatan;
    yield put({
      type: addressHomeActions.GET_DISTRICTS_HOME_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressHomeActions.GET_DISTRICTS_HOME_FAILED,
      error: e,
    });
  }
}
export function* getSubdistrictsHome(action) {
  try {
    const response = yield call(getSubdistrictsApi, action.data);
    let data = response.data.kelurahan;
    yield put({
      type: addressHomeActions.GET_SUBDISTRICTS_HOME_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressHomeActions.GET_SUBDISTRICTS_HOME_FAILED,
      error: e,
    });
  }
}
export function* getHomestatuses(action) {
  try {
    const response = yield call(getHomestatusesApi, action.data);
    let data = response.data;
    yield put({
      type: addressHomeActions.GET_HOMESTATUSES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressHomeActions.GET_HOMESTATUSES_FAILED,
      error: e,
    });
  }
}
export function* saveAddresses(action) {
  try {
    const before = JSON.stringify(action.item.defaultData.before)
    const after = JSON.stringify(action.item.defaultData.after)
      if (before !== after) {
        const response = yield call(saveAddressesApi, action.data);

        const authInfo = userInfoLocalStorage();
        if (authInfo.profile_completed) {
          yield call(postProfileUpdateApi, {"update_list" : [ADDRESS_INFO]})
        }

        let data = response.data;
        yield put({
          type: addressHomeActions.SAVE_ADDRESSES_SUCCESS,
          data,
        });

      } else yield put({ type: "NOT_LOADING" })
      if (action.item.type === 'next') history.push('lender-personal1');
      else history.push('lender-navigation');
  } catch (e) {
    yield put({
      type: addressHomeActions.SAVE_ADDRESSES_FAILED,
      error: e,
    });
    if (action.item.type === 'next') history.push('lender-personal1');
    else history.push('lender-navigation');

  }
}

export function* getAddresses(action) {
  try {
    const response = yield call(getAddressApi, action.data);
    let data = response.data;
    yield put({
      type: addressHomeActions.GET_ADDRESSES_SUCCESS,
      data,
    });
    yield put({
      type: addressActions.SET_DATA,
      data,
    })
  } catch (e) {
    if (action.from ==="data-home") {
      history.push("lender-address")
    }
    yield put({
      type: addressHomeActions.GET_ADDRESSES_FAILED,
      error:e,
    })
  }
}


export default all([
  takeLatest(addressHomeActions.GET_PROVINCES_HOME_REQUEST, getProvincesHome),
  takeLatest(addressHomeActions.GET_CITIES_HOME_REQUEST, getCitiesHome),
  takeLatest(addressHomeActions.GET_DISTRICTS_HOME_REQUEST, getDistrictsHome),
  takeLatest(addressHomeActions.GET_SUBDISTRICTS_HOME_REQUEST, getSubdistrictsHome),
  takeLatest(addressHomeActions.GET_HOMESTATUSES_REQUEST, getHomestatuses),
  takeLatest(addressHomeActions.SAVE_ADDRESSES_REQUEST, saveAddresses),
  takeLatest(addressHomeActions.GET_ADDRESSES_REQUEST, getAddresses),
]);
