import {
  GET_PROVINCES_SUCCESS,
  SET_PROVINCE_ID,
  GET_CITIES_SUCCESS,
  SET_CITY_ID,
  GET_DISTRICTS_SUCCESS,
  SET_DISTRICT_ID,
  GET_SUBDISTRICTS_SUCCESS,
  SET_SUBDISTRICT_ID,
  SET_DATA_FIELD,
  SET_DATA, SET_ADDRESS,
} from '../actions/address';
import { whenFieldSelectIsZero, checkNameWithCode } from '../config/utils';

const initialState = {
  loading: false,
  provinces: [],
  cities: [],
  districts: [],
  subdistricts: [],
  dataDefault:{},
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROVINCES_SUCCESS:      
      return {
        ...state,  
        provinces: action.data,
        data : {
          ...state.data,
          province_id: {
            ...state.data.province_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.province_id?.id) : undefined,
          },
        }         
      }; 
    case SET_PROVINCE_ID: //clear all next selected field and arrays    
      return {
        ...state,  
        cities: [],        
        districts: [],
        subdistricts: [],    
        data: Object.entries({...state.data,province_id: action.data}).reduce((acc, [k, v ]) => (['city_id','district_id','subdistrict_id'].includes(k)?acc:{...acc,[k]:v}),{})        
      }; 
    case GET_CITIES_SUCCESS:      
      return {
        ...state,        
        cities: action.data, 
        data : {
          ...state.data,
          city_id: {
            ...state.data.city_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.city_id?.id) : undefined,
          },
        }
      };
    case SET_CITY_ID:  //clear all next selected field and arrays
      return {
        ...state,                
        districts: [],
        subdistricts: [],         
        data: Object.entries({...state.data,city_id: action.data})
          .reduce((acc, [ k, v ]) => (['district_id','subdistrict_id'].includes(k)?acc:{...acc,[k]:v}),{})        
      }; 
    case GET_DISTRICTS_SUCCESS:      
      return {
        ...state,
        districts: action.data,
        data : {
          ...state.data,
          district_id: {
            ...state.data.district_id,
            name : action.data.length  ? checkNameWithCode(action.data, state.data.district_id?.id) : undefined,
          },
        }
      }; 
    case SET_DISTRICT_ID:    //clear all next selected field and arrays        
      return {
        ...state,        
        subdistricts: [],  
        data: Object.entries({...state.data,district_id: action.data})
          .reduce((acc, [ k, v ]) => (k==='subdistrict_id'?acc:{...acc,[k]:v}),{}) 
      };
    case GET_SUBDISTRICTS_SUCCESS:      
      return {
        ...state,
        subdistricts: action.data,
        data : {
          ...state.data,
          subdistrict_id: {
            ...state.data.subdistrict_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.subdistrict_id?.id) : undefined,
          },
        }
      };
    case SET_SUBDISTRICT_ID:           
      return {
        ...state,        
        data:{
          ...state.data,
          subdistrict_id: action.data,
        }
      };
    case SET_DATA_FIELD:           
      return {
        ...state,        
        data:{
          ...state.data,
          [action.data.name]: action.data.value,
        }
      };
    case SET_DATA:
      const newData = {
        ...state.data,
        address: action.data.address,
        rt: action.data.rt,
        rw: action.data.rw,
        postal_code: action.data.postal_code,
        province_id: {
          ...state.data.province_id,
          id : action.data.province_id,
          name : action.data.province_id === 0 ? undefined : checkNameWithCode(state.provinces, action.data.province_id),
        },
        city_id: {
          ...state.data.city_id,
          id : action.data.city_id,
          name : action.data.province_id === 0 ? undefined : checkNameWithCode(state.cities, action.data.city_id),
        
        },
        district_id: {
          ...state.data.district_id,
          id : action.data.district_id,
          name : action.data.province_id === 0 ? undefined : checkNameWithCode(state.districts, action.data.district_id),
        },
        subdistrict_id: {
          ...state.data.subdistrict_id,
          id : action.data.subdistrict_id,
          name : action.data.province_id === 0 ? undefined : checkNameWithCode(state.subdistricts, action.data.subdistrict_id),
        },
      }
      return {
        ...state,
        dataDefault: newData,
        data: newData
      };
    case SET_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          province_id: {
            ...state.province_id,
            id : action.data.provincesTemp.code,
            name : action.data.provincesTemp.name,
          },
          city_id: {
            ...state.city_id,
            id : action.data.citiesTemp.code,
            name : action.data.citiesTemp.name,
          },
          district_id: {
            ...state.district_id,
            id : action.data.districtsTemp.code,
            name : action.data.districtsTemp.name,
          },
          subdistrict_id: {
            ...state.subdistrict_id,
            id : action.data.subdistrictsTemp.code,
            name : action.data.subdistrictsTemp.name,
          },
        },
      };
    default:
      return state;
  }
}


