import { defaultActionCreator } from './actionCreator';

export const GET_PROVINCES_REQUEST = 'GET_PROVINCES_REQUEST'
export const GET_PROVINCES_SUCCESS = 'GET_PROVINCES_SUCCESS'
export const GET_PROVINCES_FAILED = 'GET_PROVINCES_FAILED'
export const getProvincesRequest = defaultActionCreator(GET_PROVINCES_REQUEST, 'data')
export const getProvincesSuccess = defaultActionCreator(GET_PROVINCES_SUCCESS, 'data')
export const getProvincesFailed = defaultActionCreator(GET_PROVINCES_FAILED, 'error')

export const SET_PROVINCE_ID = 'SET_PROVINCE_ID';
export const setProvinceId = defaultActionCreator(SET_PROVINCE_ID, 'data');

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILED = 'GET_CITIES_FAILED'
export const getCitiesRequest = defaultActionCreator(GET_CITIES_REQUEST, 'data')
export const getCitiesSuccess = defaultActionCreator(GET_CITIES_SUCCESS, 'data')
export const getCitiesFailed = defaultActionCreator(GET_CITIES_FAILED, 'error')

export const SET_CITY_ID = 'SET_CITY_ID';
export const setCityId = defaultActionCreator(SET_CITY_ID, 'data');

export const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST'
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS'
export const GET_DISTRICTS_FAILED = 'GET_DISTRICTS_FAILED'
export const getDistrictsRequest = defaultActionCreator(GET_DISTRICTS_REQUEST, 'data')
export const getDistrictsSuccess = defaultActionCreator(GET_DISTRICTS_SUCCESS, 'data')
export const getDistrictsFailed = defaultActionCreator(GET_DISTRICTS_FAILED, 'error')

export const SET_DISTRICT_ID = 'SET_DISTRICT_ID';
export const setDistrictId = defaultActionCreator(SET_DISTRICT_ID, 'data');

export const GET_SUBDISTRICTS_REQUEST = 'GET_SUBDISTRICTS_REQUEST'
export const GET_SUBDISTRICTS_SUCCESS = 'GET_SUBDISTRICTS_SUCCESS'
export const GET_SUBDISTRICTS_FAILED = 'GET_SUBDISTRICTS_FAILED'
export const getSubdistrictsRequest = defaultActionCreator(GET_SUBDISTRICTS_REQUEST, 'data')
export const getSubdistrictsSuccess = defaultActionCreator(GET_SUBDISTRICTS_SUCCESS, 'data')
export const getSubdistrictsFailed = defaultActionCreator(GET_SUBDISTRICTS_FAILED, 'error')

export const SET_SUBDISTRICT_ID = 'SET_SUBDISTRICT_ID';
export const setSubdistrictId = defaultActionCreator(SET_SUBDISTRICT_ID, 'data');

export const SET_DATA_FIELD = 'SET_DATA_FIELD';
export const setDataField = defaultActionCreator(SET_DATA_FIELD, 'data');

export const SET_DATA = 'SET_DATA';
export const setData = defaultActionCreator(SET_DATA, 'data');

export const SET_ADDRESS = 'SET_ADDRESS';
export const setAddress = defaultActionCreator(SET_ADDRESS,'data')