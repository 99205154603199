import { defaultActionCreator } from './actionCreator';

export const GET_NPWP_CITIES_REQUEST = 'GET_NPWP_CITIES_REQUEST'
export const GET_NPWP_CITIES_SUCCESS = 'GET_NPWP_CITIES_SUCCESS'
export const GET_NPWP_CITIES_FAILED = 'GET_NPWP_CITIES_FAILED'
export const getNpwpCitiesRequest = defaultActionCreator(GET_NPWP_CITIES_REQUEST, 'data')
export const getNpwpCitiesSuccess = defaultActionCreator(GET_NPWP_CITIES_SUCCESS, 'data')
export const getNpwpCitiesFailed = defaultActionCreator(GET_NPWP_CITIES_FAILED, 'error')

export const SET_NPWP_CITY_ID = 'SET_NPWP_CITY_ID';
export const setNpwpCityId = defaultActionCreator(SET_NPWP_CITY_ID, 'data');

export const SET_DATA_FIELD_TAX = 'SET_DATA_FIELD_TAX';
export const setDataFieldTax = defaultActionCreator(SET_DATA_FIELD_TAX, 'data');

export const SAVE_TAX_REQUEST = 'SAVE_TAX_REQUEST'
export const SAVE_TAX_SUCCESS = 'SAVE_TAX_SUCCESS'
export const SAVE_TAX_FAILED = 'SAVE_TAX_FAILED'
export const saveTaxRequest = defaultActionCreator(SAVE_TAX_REQUEST, 'data','item')
export const saveTaxSuccess = defaultActionCreator(SAVE_TAX_SUCCESS, 'data')
export const saveTaxFailed = defaultActionCreator(SAVE_TAX_FAILED, 'error')

export const GET_TAX_REQUEST = 'GET_TAX_REQUEST'
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS'
export const GET_TAX_FAILED = 'GET_TAX_FAILED'
export const GET_TAX_NPWP_SUCCESS= "GET_TAX_NPWP_SUCCESS"
export const GET_TAX_NPWP_FAILED= "GET_TAX_NPWP_FAILED"
export const getTaxRequest = defaultActionCreator(GET_TAX_REQUEST,'data')

export const SET_TAX = 'SET_TAX'
export const setTax = defaultActionCreator(SET_TAX,'data')

export const SET_STATE_TAX = 'SET_STATE_TAX'
export const setStateTax = defaultActionCreator(SET_STATE_TAX,'name','value')
