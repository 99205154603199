import {all, call, put, takeLatest} from "redux-saga/effects";
import * as credentialAction from "../actions/credential";
import {getCredentialInfo} from "../requests/credential";

export function* getCredential(action) {
    try {
        const response = yield call(getCredentialInfo, action.data);
        let data = response.data;
        yield put({
            type: credentialAction.GET_CREDENTIAL_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: credentialAction.GET_CREDENTIAL_ERROR,
            error: e,
        });
    }
}

export default all([
    takeLatest(credentialAction.GET_CREDENTIAL_REQUEST, getCredential),
]);