import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as personal2Actions from '../actions/personal2';
import * as personal1Action from "../actions/personal1";
import {
  getReligionsApi,
  getMaritalStatusesApi,
  getEducationLevelsApi,
  getInvestmentPurposesApi,
  savePersonalInfoApi,
  getPersonalInfoApi,
} from '../requests/personal2';
import history from '../history';
import {postProfileUpdateApi} from '../requests/navigation'
import {userInfoLocalStorage} from '../config/utils'
import {PERSONAL_INFO} from '../helpers/globalConst'

export function* getReligions(action) {
  try {
    const response = yield call(getReligionsApi, action.data);
    let data = response.data;
    yield put({
      type: personal2Actions.GET_RELIGIONS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: personal2Actions.GET_RELIGIONS_FAILED,
      error: e,
    });
  }
}
export function* getMaritalStatuses(action) {
  try {
    const response = yield call(getMaritalStatusesApi, action.data);
    let data = response.data;
    yield put({
      type: personal2Actions.GET_MARITAL_STATUSES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: personal2Actions.GET_MARITAL_STATUSES_FAILED,
      error: e,
    });
  }
}
export function* getEducationLevels(action) {
  try {
    const response = yield call(getEducationLevelsApi, action.data);
    let data = response.data;
    yield put({
      type: personal2Actions.GET_EDUCATION_LEVELS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: personal2Actions.GET_EDUCATION_LEVELS_FAILED,
      error: e,
    });
  }
}
export function* getInvestmentPurposes(action) {
  try {
    const response = yield call(getInvestmentPurposesApi, action.data);
    let data = response.data;
    yield put({
      type: personal2Actions.GET_INVESTMENT_PURPOSES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: personal2Actions.GET_INVESTMENT_PURPOSES_FAILED,
      error: e,
    });
  }
}
export function* savePersonalInfo(action) {
  try {
    const before = JSON.stringify(action.item.defaultData.before)
    const after = JSON.stringify(action.item.defaultData.after)
    if (before !== after) {
      const response = yield call(savePersonalInfoApi, action.data);
      const authInfo = userInfoLocalStorage();
      if (authInfo.profile_completed) {
        yield call(postProfileUpdateApi, {"update_list" : [PERSONAL_INFO]})
      }

      let data = response.data;
      yield put({
        type: personal2Actions.SAVE_PERSONAL_INFO_SUCCESS,
        data,
      });

    } else yield put({ type: "NOT_LOADING" })
    if (action.item.type === 'next') history.push("lender-business");
    else history.push('lender-navigation');
  } catch (e) {
    yield put({
      type: personal2Actions.SAVE_PERSONAL_INFO_FAILED,
      error: e,
    });
    if (action.item.type === 'next') history.push("lender-business");
    else history.push('lender-navigation');
  }
}

export function* getPersonalInfo(action) {
  try {
    const response = yield call(getPersonalInfoApi, action.data);
    let data = response.data;
    yield put({
      type: personal2Actions.GET_PERSONAL_INFO_SUCCESS,
      data,
    });
    yield put({
      type: personal1Action.SET_PERSONAL_INFO,
      data,
    });
  } catch (e) {
    if (action.from === "data-personal2") {
      history.push("lender-personal1")
    }
    yield put({
      type: personal2Actions.GET_PERSONAL_INFO_FAILED,
      error: e,
    });
  }
}

export default all([
  takeLatest(personal2Actions.GET_RELIGIONS_REQUEST, getReligions),
  takeLatest(personal2Actions.GET_MARITAL_STATUSES_REQUEST, getMaritalStatuses),
  takeLatest(personal2Actions.GET_EDUCATION_LEVELS_REQUEST, getEducationLevels),
  takeLatest(personal2Actions.GET_INVESTMENT_PURPOSES_REQUEST, getInvestmentPurposes),
  takeLatest(personal2Actions.SAVE_PERSONAL_INFO_REQUEST, savePersonalInfo),
  takeLatest(personal2Actions.GET_PERSONAL_INFO_REQUEST, getPersonalInfo)
]);
