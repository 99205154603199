import { all, call, put, takeLatest } from "redux-saga/effects";
import * as navigationActions from "../actions/navigation";
import history from "../history";
import { getProgressApi,postProfileCompletedApi, getRDLAgreementApi } from "../requests/navigation";

export function* getProgress(action) {
    try {
        const response = yield call(getProgressApi, action.data);
        let data = response.data;
        yield put({
            type: navigationActions.GET_PROGRESS_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: navigationActions.GET_PROGRESS_FAILED,
            
        });
    }
}
export function* postProfileCompleted(action){
    try {
        const response = yield call(postProfileCompletedApi, action.data);
        let data = response.data;
        yield put({
            type: navigationActions.POST_PROFILE_COMPLETED_SUCCESS,
            data,
        });
        const auth = localStorage.getItem("auth_info");
        let authInfo = JSON.parse(auth == null ? "" : auth);
        if(authInfo){
            authInfo.profile_completed = true
            authInfo = JSON.stringify(authInfo)
            localStorage.setItem('auth_info', authInfo)
        }
        history.push("thankyounew")
    } catch (e) {
        yield put({
            type: navigationActions.POST_PROFILE_COMPLETED_FAILED,
        });
    }
}
export function* getRDLAgreement(action) {
    try {
        const response = yield call(getRDLAgreementApi, action.data);
        let data = response.data.url;
        yield put({
            type: navigationActions.GET_RDL_AGREEMENT_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: navigationActions.GET_RDL_AGREEMENT_FAILED,
            
        });
    }
}
export default all([
    takeLatest(navigationActions.GET_PROGRESS_REQUEST, getProgress),
    takeLatest(navigationActions.POST_PROFILE_COMPLETED_REQUEST, postProfileCompleted),
    takeLatest(navigationActions.GET_RDL_AGREEMENT_REQUEST, getRDLAgreement),
]);