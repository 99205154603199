/* eslint-disable no-useless-escape */
import Cookies from "universal-cookie";
import { getEndpoints } from "../helpers";
import history from "../history";

export const wording = () => {
  let wordingtext = localStorage.getItem("wording");
  return JSON.parse(wordingtext);
};

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const whenFieldSelectIsZero = (data, id, name, ) =>
  data[id] === 0 ? undefined : data[name];

export function toDataUrl(url) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status === 200) {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve(reader.result);
        };
      } else {
        reject(new Error('Request failed with status: ' + xhr.status));
      }
    };
    xhr.send();
  });
}

export const userInfoLocalStorage = () => {
  try {
    const auth = localStorage.getItem("auth_info");
    const authInfo = JSON.parse(auth == null ? "" : auth);
    return authInfo;
  } catch (error) {
    return;
  }
};

export const isDisableField = (item, navigation, navigationProgress) => {
  const authInfo = userInfoLocalStorage();
  try {
    if (authInfo.profile_completed) {
      if (authInfo.is_rejected) return false;
        if (
          (navigation != null &&
            typeof navigation[navigationProgress] == "object" &&
            navigation[navigationProgress].includes(item)) ||
          (navigation != null &&
            typeof navigation[navigationProgress] == "boolean" &&
            !navigation[navigationProgress])
        ) return false;
      else return true;
    } else {
      return false
    };
  } catch (error) {
    return false;
  }
};

export const checkAuth = async () => {
  let profileToken = localStorage.getItem("profile_token");
  let userToken = localStorage.getItem("access_token");
  if (profileToken == null || userToken == null) {
    removeLocalstorage();
  } else {
    // try {
    //   await authInfo(userToken, 'user')
    //   await authInfo(profileToken, 'profile')
    // } catch (error) {
    //   console.log(error);
    // }
  }
};
export const getCookieLocal = () => {
  const cookies = new Cookies();
  const acToken = cookies.get("access_token");
  const refToken = cookies.get("refresh_token");
  const user_id = cookies.get("user_id");
  if (acToken == null || refToken == null || user_id == null) {
    removeLocalstorage();
  }
  checkAuth();
};

export const domainFromUrl = (url) => {
  var result;
  var match;
  if (
    (match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
    ))
  ) {
    result = match[1];
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
};

export const removeCookies = () => {
  const cookies = new Cookies();

  cookies.set("access_token", "", {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge: 0,
  });
  cookies.set("refresh_token", "", {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge: 0,
  });
  cookies.set("user_id", "", {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge: 0,
  });
  cookies.set("_session_mekar", false, {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge: 0,
  });
  removeLocalstorageItems();

  window.location.href = getEndpoints.mekarBaseUrl + "user/logout_home/";
};

export const setCookieLocalsorage = (user) => {
  const cookies = new Cookies();
  const maxAge = getSessionMaxAge();
  const { profile_completed, profile_verified, registration_info } = user;

  localStorage.setItem("lenderId", user.id);
  localStorage.setItem("emailOtp", user.username);
  localStorage.setItem("username", user.name);
  localStorage.setItem("access_token", user.token.access);
  localStorage.setItem("refresh_token", user.token.refresh);
  localStorage.setItem(
    "auth_info",
    JSON.stringify({
      profile_completed,
      profile_verified,
      ...registration_info,
    })
  );
  cookies.set("access_token", user.token.access, {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge,
  });
  cookies.set("refresh_token", user.token.refresh, {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge,
  });
  cookies.set("user_id", user.id, {
    path: "/",
    domain: getEndpoints.cookiesUrl,
    maxAge,
  });
  cookies.set("_session_mekar", true, {
    path: "/",
    domain: getEndpoints.cookiesUrl,
  });
  if (user.role === "RISK")
    cookies.set("auth_url", `risk--batch-instruments`, {
      path: "/",
      domain: getEndpoints.cookiesUrl,
    });
  else if (user.role === "SALES-LENDING")
    cookies.set("auth_url", `sales-lending--batch-instruments`, {
      path: "/",
      domain: getEndpoints.cookiesUrl,
    });
  else
    cookies.set("auth_url", ``, { path: "/", domain: getEndpoints.cookiesUrl });
};

export const removeLocalstorageItems = () => {
  localStorage.removeItem("username");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("profile_token");
  localStorage.removeItem("lenderId");
  localStorage.removeItem("phone");
  localStorage.removeItem("emailOtp");
  localStorage.removeItem("auth_info");
  localStorage.removeItem("role");
};

export const removeLocalstorage = () => {
  removeLocalstorageItems();
  history.push("/");
};
export const onlyAlpha = (text) =>
  text
    .replace(/[0-9\/]+/g, "")
    .replace(/[-!$%^&*()_+|~=`{}[\]:";'<>?,.@#\/]/g, "")
    .replace("\\", "");
export const onlyNumeric = (text) =>
  text
    .replace(/[^0-9\/]+/g, "")
    .replace(/[-!$%^&*()_+|~=`{}[\]:";'<>?,.@#\/]/g, "")
    .replace("\\", "");

export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    console.log("You can only upload JPG or PNG file!");
    console.log("Your file type is : ", file.type)
  }
  // const isLt5M = file.size / 1024 / 1024 < 2;
  // if (!isLt5M) {
  //   console.log('Image must smaller than 2MB!');
  // }
  return isJpgOrPng;
}
export const fileReaderHandler = (file) => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const getImageProperties = (file,imageSrc, callback, maxWidth = 1000, maxHeight = 1000) => {
  const img = document.createElement("img");
  img.src = imageSrc;
  img.crossOrigin = "anonymous";
  img.style.display = "none";
  document.body.appendChild(img);
  let colorSum = 0;

  img.onload = function () {
    const canvas = document.createElement("canvas");
    const MAX_WIDTH = maxWidth
    const MAX_HEIGHT = maxHeight
    let width = img.width
    let height = img.height
    const sizeImg = Math.round(file.size/1024)
    if (sizeImg > 1) {
      if (width > height) {
        if (width > MAX_WIDTH) {
          const heightN = height * (MAX_WIDTH / width )
          height = Math.round(heightN + (heightN/2))
          width = Math.round(MAX_WIDTH + (heightN/2))
        }
      } else {
        if (height > MAX_HEIGHT) {
         const widthN = width * (MAX_HEIGHT / height) 
          width = Math.round(widthN + (widthN/2))
          height = Math.round(MAX_HEIGHT + (widthN/2))
        }
      }
    }
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    let r, g, b, avg;

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];
      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }

    const brightness = Math.floor(colorSum / (img.width * img.height));
    callback(brightness, canvas.toDataURL("image/jpeg"));
  }
}

export function validatorField(condition, messageError) {
  return new Promise(function (resolve, reject) {
    if (condition) return reject(messageError);
    return resolve();
  });
}
export const switchRouteProfileCompletedIsFalse = (data, totalIsField) => {
  switch (data.role) {
    case "LENDER":
      history.push("lender-type");
      break;
    case "LENDER-INDIVIDUAL":
      switchRoute(totalIsField);
      break;
    case "LENDER-INSTITUTIONAL":
      window.location.href = getEndpoints.mekarBaseUrl + "dashboard/";
      break;
    case "LENDER-MEDIA":
      history.push("register?user=lender-media");
      break;
    default:
      history.push("/");
  }
};

export const switchRoute = async (totalIsField) => {
  const condition = (data) =>
    data.length === 0 || (typeof data === "boolean" && data) ? true : false;
  if (totalIsField === "") history.push("lender-navigation");
  else {
    const data = totalIsField.data;
    if (data !== null) {
      if (condition(data.selfie)) {
        if (condition(data.selfie_identity)) {
          if (condition(data.identity)) {
            if (condition(data.address)) {
              if (condition(data.personalinfo)) {
                if (condition(data.businessinfo)) {
                  if (condition(data.taxinfo)) {
                    if (condition(data.bankinfo))
                      history.push("/lender-navigation");
                    else history.push("/lender-bank");
                  } else history.push("/lender-tax");
                } else history.push("/lender-business");
              } else history.push("/lender-personal1");
            } else history.push("/lender-address");
          } else history.push("/lender-document?topic=identity");
        } else history.push("/lender-document?topic=selfie-identity");
      } else history.push("/lender-document");
    }
  }
};

export const formatMoney = (num) => {
  return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
};

export const capitalize = (str, allWord) => {
  const cap = (text) => `${text[0].toUpperCase()}${text.slice(1)}`;
  return allWord
    ? str
        .split(" ")
        .map((word) => cap(word))
        .join(" ")
    : cap(str);
};

export const monthName = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const dayInWeek = [
  { name: "Se", fullName: "Senin" },
  { name: "Se", fullName: "Selasa" },
  { name: "Ra", fullName: "Rabu" },
  { name: "Ka", fullName: "Kamis" },
  { name: "Ju", fullName: "Jumat" },
  { name: "Sa", fullName: "Sabtu" },
  { name: "Mi", fullName: "Minggu" },
];

export const convertDate = (item) => {
  const newDate = new Date(item);
  const date = newDate.getDate();
  const monthsx = newDate.getMonth();
  const year = newDate.getFullYear();
  const month = monthName[monthsx];
  let h = newDate.getHours().toString();
  let m = newDate.getMinutes().toString();
  let s = newDate.getSeconds().toString();
  if (h.length < 2) {
    h = `0${h}`;
  }
  if (m.length < 2) {
    m = `0${m}`;
  }
  if (s.length < 2) {
    s = `0${s}`;
  }
  const dTime = h + ":" + m;
  return {
    date,
    month,
    year,
    dTime,
    full: `${date} ${month} ${year}`,
    ddmmyyyy: `${date}-${String(newDate.getMonth()).padStart(2, "0")}-${year}`,
    yyyymmdd: `${year}-${String(newDate.getMonth()).padStart(2, "0")}-${date}`,
  };
};

export const authLocalStorage = () => {
  const cookies = new Cookies();
  const session = cookies.get("access_token");
  if (session === undefined) {
    const _session_mekar = cookies.get("_session_mekar");
    if (_session_mekar ==="true") {
      cookies.set("_session_mekar", false, {
        path: "/",
        domain: getEndpoints.cookiesUrl,
      });
    }
    return undefined;
  }
  try {
    const role = localStorage.getItem("role");
    const auth = localStorage.getItem("auth_info");
    const authInfo = JSON.parse(auth == null ? "" : auth);
    const profile = localStorage.getItem("profile_token");
    const access = localStorage.getItem("access_token");
    if (auth === undefined || profile === undefined || access === undefined) {
      return undefined;
    }
    return { authInfo, profile, access, role };
  } catch (error) {
    return undefined;
  }
};

export function daysInMonth(iMonth, iYear) {
  return 32 - new Date(iYear, iMonth, 32).getDate();
}

// eslint-disable-next-line no-script-url
export const xssValid = (value) =>
  value.match(/(<[^>]*>)/g) != null ||
  value.toLowerCase().includes("javascript:") ||
  value.toLowerCase().includes("data:")
    ? Promise.reject("Teks tidak valid!")
    : Promise.resolve();

export const xssValidBool = (value) =>
  value.match(/(<[^>]*>)/g) != null ||
  value.toLowerCase().includes("javascript:") ||
  value.toLowerCase().includes("data:")
    ? false
    : true;

const getSessionMaxAge = () => {
  // const init = undefined;
  const init = process.env.REACT_APP_SESSION_MAX_AGE;
  const defaultMaxAge = 3600; // 1 jam
  try {
    let value = init.split("-");
    if (value[1] === "s") {
      // detik
      return Number(value[0]);
    } else if (value[1] === "m") {
      // menit
      return Number(value[0]) * 60;
    } else if (value[1] === "h") {
      // jam
      return Number(value[0]) * 3600;
    } else {
      return defaultMaxAge;
    }
  } catch (error) {
    return defaultMaxAge;
  }
};

export const checkNameWithCode = (list, code, byId=false) => {
  let name 
  list.forEach(element => {
    if(parseInt(element.code) && !byId){
      if(parseInt(element.code) == code){
        name = element.name
      }
    } else {
      if(parseInt(element.id) === code){
        name = element.name
      }
    }
  });
  return name
}

export const flipImage = async (imgSrc) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.setTransform(-1, 0, 0, 1, img.width, 0);
      ctx.drawImage(img, 0, 0);
      const mirroredImage = canvas.toDataURL();
      resolve(mirroredImage)
    };

    img.onerror = function (event) {
      reject(event)
    };
 
    img.src = imgSrc;
  });
};
