import service from './service';
import { API_URL, API_URL_CLOUD } from '../config/config';
import { get } from './serviceNew'
import { getByAccessToken } from './serviceNew'


export const getOccupationsApi = () => {
  return service.extGet(`${API_URL_CLOUD}/occupation`);
};

export const getCompanyIndustriesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/company_industry_v2`);
};

export const getWorkExperiencesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/work_experience`);
};

export const getAverageIncomesApi = () => {
  return service.extGet(`${API_URL_CLOUD}/average_income`);
};

export const getAverageOutcomeApi = () => {
  return service.extGet(`${API_URL_CLOUD}/average_outcome`);
};

export const getDataApi = (path) => {
  return service.extGet(API_URL_CLOUD + path);
};


export const saveBusinessInfoApi = data => {
  return service.postAccessToken(
    `${API_URL}businessinfo/`,
    data.body,
  );
};

export const getBusinessInfoApi = data => {
  return getByAccessToken(
    `${API_URL}businessinfo/?user_id=${data.body.user_id}&get_names`
  );
}
