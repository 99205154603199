import { notification } from "antd";
import React from "react";

export const WarningNotificationLoanFileError = (fileError) => {
  return notification.warning({
    key: "registration-warning",
    description: (
      <>
        <strong>{fileError.join(" & ")} </strong> , Nama File / Nama Sheet harus
        sesuai templete yang sudah disediakan.
      </>
    ),
    message: `Terdapat file yg tidak sesuai`,
    duration: 0,
  });
};
