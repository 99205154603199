import React, { ReactChild } from 'react';
import { Helmet } from 'react-helmet-async';
import DashboardLayout from '../layout/DashboardLayout';
import HeaderNew from './HeaderNew';
import Arrow from './assets/icons/ic-right-arrow.svg';
import { Link } from 'react-router-dom';
import { capitalize } from '../config/utils';
import { Location } from 'history';

type Props = {
  children: ReactChild;
  title?: string;
  titleLayout?: string;
  submenu?: string | undefined;
  type?: 'v2' | undefined;
  backTo?: Location | string;
};
const Layout: React.FC<Props> = ({
  children,
  title,
  titleLayout,
  submenu,
  type,
  backTo,
}) => {
  let username = localStorage.getItem('username');
  switch (type) {
    case 'v2' || undefined:
      return (
        <>
          <Helmet>
            <title>Mekar.id - {titleLayout ? titleLayout : title}</title>
          </Helmet>
          <HeaderNew
            submenu={submenu}
            onBackLink="hidden"
            username={username !== null ? username : ''}
          />
          <DashboardLayout className="layout-v2">
            <>
              {backTo ? (
                <>
                  <Link to={backTo} className="mekar-f-weigth-600 mb-1">
                    <img className="mk-rotate-180 mr-1 " src={Arrow} /> Kembali
                  </Link>
                  <h3 className="title-layout mb-5 mt-1">
                    {titleLayout}
                  </h3>
                </>
              ) : (
                <h3 className="title-layout mb-3 mekar-float-left">
                  {titleLayout?.toUpperCase()}
                </h3>
              )}
              {children}
            </>
          </DashboardLayout>
        </>
      );
    default:
      return (
        <>
          <Helmet>
            <title>Mekar.id </title>
          </Helmet>
          {children}
        </>
      );
  }
};

export default Layout;
