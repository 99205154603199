import Login from "./Login/lazy";
import Register from "./Register/lazy";
import ForgotPassword from "./ForgotPassword/lazy";
import ChangePassword from "./ChangePassword/lazy";
import InputOTP from "./OTP/lazy";
import CaptureDocument from "./CaptureDocument/lazy";
import Thankyou from "./Thankyou/lazy";
import Choice from "./Choices/lazy";
import LenderDocument from "./Profile/Document/lazy";
import LenderAddress from "./Profile/Address";
import LenderAddressHome from "./Profile/AddressHome";
import LenderBusiness from "./Profile/Business";
import LenderPersonal1 from "./Profile/Personal1";
import LenderPersonal2 from "./Profile/Personal2";
import LenderTax from "./Profile/Tax";
import LenderBank from "./Profile/Bank";
import Thankyounew from "./Profile/Thankyounew";
import ListOfProfileData from "./Profile/Navigation/lazy";
import UpdatesData from "./UpdatesData/lazy";
import UploadLoan from "./Partner/UploadLoan/lazy";
import DasboardPartner from "./Partner/Dashboard/lazy";
import Repayment from "./Partner/Repayment/lazy";
import SummaryPartner from "./Partner/ListLoan/Summary/lazy";
import Instruments from "./Partner/ListLoan/Instruments/lazy";
import InstrumentsActive from "./Partner/ListLoan/InstrumentsActive/lazy";
import InstrumentsSold from "./Partner/ListLoan/InstrumentsSold/lazy";
import PayOffPartner from "./Partner/ListLoan/PayOff/lazy";
import AccountBalance from "./Partner/Account/Balance/lazy";
import AccountTopUp from "./Partner/Account/TopUp/lazy";
import AccountWithDrawals from "./Partner/Account/WithDrawals/lazy";
import PartnerDigitalAgreement from "./Partner/Settings/PartnerDigitalAgreement/lazy";

export const routeProtect = [

  {
    path: "/lender-document",
    component: LenderDocument,
    exact: true,
  },
  {
    path: "/lender-address",
    component: LenderAddress,
    exact: true,
  },
  {
    path: "/lender-address-home",
    component: LenderAddressHome,
    exact: true,
  },
  {
    path: "/lender-personal1",
    component: LenderPersonal1,
    exact: true,
  },
  {
    path: "/lender-personal2",
    component: LenderPersonal2,
    exact: true,
  },
  {
    path: "/lender-business",
    component: LenderBusiness,
    exact: true,
  },
  {
    path: "/lender-tax",
    component: LenderTax,
    exact: true,
  },
  {
    path: "/lender-bank",
    component: LenderBank,
    exact: true,
  },
  {
    path: "/lender-navigation",
    component: ListOfProfileData,
    exact: true,
  },
  {
    path: "/notification",
    component: UpdatesData,
    exact: true,
  },
];

export const routes = [
  {
    path: "/register",
    component: Register,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    exact: true,
  },
  {
    path: "/otp-input",
    component: InputOTP,
    exact: true,
  },
  {
    path: "/capture-document",
    component: CaptureDocument,
    exact: true,
  },
  {
    path: "/lender-type",
    component: Choice,
    exact: true,
  },
  {
    path: "/thankyou",
    component: Thankyou,
    exact: true,
  },
  {
    path: "/thankyounew",
    component: Thankyounew,
    exact: true,
  },
  {
    path: "/",
    component: Login,
  },
];

export const routeProtectPartner = [
  {
    path: "/partner/dashboard",
    component: DasboardPartner,
    exact: true,
  },
  {
    path: "/partner/batch-instruments",
    component: UploadLoan,
    exact: true,
  },
  {
    path: "/partner/instruments-repayment-list",
    component: Repayment,
    exact: true,
  },
  {
    path: "/partner/loan/summary",
    component: SummaryPartner,
    exact: true,
  },
  {
    path: "/partner/loan/instruments",
    component: Instruments,
    exact: true,
  },
  {
    path: "/partner/loan/instruments-active",
    component: InstrumentsActive,
    exact: true,
  },
  {
    path: "/partner/loan/instruments-sold",
    component: InstrumentsSold,
    exact: true,
  },
  {
    path: "/partner/loan/payoff",
    component: PayOffPartner,
    exact: true,
  },
  {
    path: "/partner/account/balance",
    component: AccountBalance,
    exact: true,
  },
  {
    path: "/partner/account/topup",
    component: AccountTopUp,
    exact: true,
  },
  {
    path: "/partner/account/withdrawals",
    component: AccountWithDrawals,
    exact: true,
  },
  {
    path: "/partner/partner-digital-agreement",
    component: PartnerDigitalAgreement,
    exact: true,
  },
];

export const routeProtectStaffLoan = {
  sales: [
    {
      path: "/sales-lending/batch-instruments",
      component: UploadLoan,
      exact: true,
    },
  ],
  risk: [
    {
      path: "/risk/batch-instruments",
      component: UploadLoan,
      exact: true,
    },
  ],
};
