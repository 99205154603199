import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as occupationActions from '../actions/occupation';
import history from '../history';
import {
    getOccupationsApi,
    getCompanyIndustriesApi,
    getWorkExperiencesApi,
    getAverageIncomesApi,
    getAverageOutcomeApi,
    saveBusinessInfoApi, getBusinessInfoApi,

} from '../requests/occupation';
import {postProfileUpdateApi} from '../requests/navigation'
import {userInfoLocalStorage} from '../config/utils'
import {BUSSINESS_INFO} from '../helpers/globalConst'

export function* getOccupations(action) {
    try {
        const response = yield call(getOccupationsApi, action.data);
        let data = response.data;
        yield put({
            type: occupationActions.GET_OCCUPATIONS_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: occupationActions.GET_OCCUPATIONS_FAILED,
            error: e,
        });
    }
}


export function* getCompanyIndustries(action) {
    try {
        const response = yield call(getCompanyIndustriesApi, action.data);
        let data = response.data;
        yield put({
            type: occupationActions.GET_COMPANY_INDUSTRY_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: occupationActions.GET_COMPANY_INDUSTRY_FAILED,
            error: e,
        });
    }
}

export function* getWorkExperiences(action) {
    try {
        const response = yield call(getWorkExperiencesApi, action.data);
        let data = response.data;
        yield put({
            type: occupationActions.GET_WORK_EXPERIENCE_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: occupationActions.GET_WORK_EXPERIENCE_FAILED,
            error: e,
        });
    }
}

export function* getAverageIncomes(action) {
    try {
        const response = yield call(getAverageIncomesApi, action.data);
        let data = response.data;
        yield put({
            type: occupationActions.GET_AVERAGE_INCOME_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: occupationActions.GET_AVERAGE_INCOME_FAILED,
            error: e,
        });
    }
}

export function* getAverageOutcome(action) {
    try {
        const response = yield call(getAverageOutcomeApi, action.data);
        let data = response.data;
        yield put({
            type: occupationActions.GET_AVERAGE_OUTCOME_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: occupationActions.GET_AVERAGE_OUTCOME_FAILED,
            error: e,
        });
    }
}
export function* saveBusinessInfo(action) {
    try {        
        const response = yield call(saveBusinessInfoApi, action.data);
        const authInfo = userInfoLocalStorage();
        if (authInfo.profile_completed) {
          yield call(postProfileUpdateApi, {"update_list" : [BUSSINESS_INFO]})
        }

        let data = response.data;
        yield put({
            type: occupationActions.SAVE_OCCUPATION_INFO_SUCCESS,
            data,
        });
    
        if (action.item.type === 'next') history.push('lender-tax');
        else history.push('lender-navigation');
    } catch (e) {
        yield put({
            type: occupationActions.SAVE_OCCUPATION_INFO_FAILED,
            error: e,
        });
        if (action.item.type === 'next') history.push('lender-tax');
        else history.push('lender-navigation');
    }
}

export function* getBusinessInfo(action) {
    try {
        const response = yield call(getBusinessInfoApi, action.data);
        let data = response.data;
        yield put({
            type: occupationActions.GET_BUSINESS_INFO_SUCCESS,
            data,
        });
    } catch (e) {
        yield put({
            type: occupationActions.GET_BUSINESS_INFO_FAILED,
            error: e,
        });
    }
}

export default all([
    takeLatest(occupationActions.GET_OCCUPATIONS_REQUEST, getOccupations),
    takeLatest(occupationActions.GET_COMPANY_INDUSTRY_REQUEST, getCompanyIndustries),
    takeLatest(occupationActions.GET_WORK_EXPERIENCE_REQUEST, getWorkExperiences),
    takeLatest(occupationActions.GET_AVERAGE_INCOME_REQUEST, getAverageIncomes),
    takeLatest(occupationActions.GET_AVERAGE_OUTCOME_REQUEST, getAverageOutcome),
    takeLatest(occupationActions.SAVE_OCCUPATION_INFO_REQUEST, saveBusinessInfo),
    takeLatest(occupationActions.GET_BUSINESS_INFO_REQUEST, getBusinessInfo)
]);
