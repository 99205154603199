import axios from "axios";
import { removeLocalstorage } from "../config/utils";


export const get = async function (path, params) {
  let token = localStorage.getItem('profile_token');
  return await axios.get(
    path,
    {
      headers: {
        'Authorization': 'Bearer ' + token,
        "Content-Type": "application/json"
      }
    }
  ).catch((error) => {
    if (error.response.status === 401) {
      console.clear();
      removeLocalstorage()
    }
  });
}

export const getByAccessToken = async function (path, params) {
  let token = localStorage.getItem('access_token');
  return await axios.get(
    path,
    {
      headers: {
        'Authorization': 'Bearer ' + token,
        "Content-Type": "application/json"
      }
    }
  ).catch((error) => {
    if (error.response.status === 401) {
      console.clear();
      removeLocalstorage()
    }
  });
}