import React from "react";
import './StepCounter.scss';

type Props = {
  step: number,
  total: number
};

const StepCounter: React.FC<Props> = ({ step, total }) => {
  // useEffect(() => {  
  //   console.log("StepCounter==>>:","step:",step,"total:",total);   
  //   return () => {};
  // },[]);
  return (
    <div className="StepCounter">      
     <span>{step}</span> dari <span>{total}</span>
    </div>
  );
};

export default StepCounter;
