import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as utilityActions from '../actions/utility';
import { getRatingApi, getSektorBisnisApi } from '../requests/utility';

export function* getSektorUsaha() {
  try {
    const response = yield call(getSektorBisnisApi);
    yield put({
      type: utilityActions.GET_SEKTOR_USAHA_SUCCESS,
      data: response.data,
    });
  } catch (e) {    
    yield put({
      type: utilityActions.GET_SEKTOR_USAHA_FAILED,
      error: e,
    });
  }
}

export function* getRating() {
  try {
    const response = yield call(getRatingApi);
    yield put({
      type: utilityActions.GET_RATING_SUCCESS,
      data: response.data,
    });
  } catch (e) {    
    yield put({
      type: utilityActions.GET_RATING_FAILED,
      error: e,
    });
  }
}

export default all([
  takeLatest(utilityActions.GET_SEKTOR_USAHA_REQUEST, getSektorUsaha), 
  takeLatest(utilityActions.GET_RATING_REQUEST, getRating), 
]);
