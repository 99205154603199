export const API_URL =
  process.env.REACT_APP_PROFILE_SERVICE_URL + 'api/lender/';
export const API_URL_CLOUD = process.env.REACT_APP_CLOUD_FUNCTION_URL;
export const Bearer = localStorage.getItem('profile_token');
export const API_PDF_SERVICE =
  process.env.REACT_APP_PDF_SERVICE_URL ;
const configs = {
  API_URL,
  API_URL_CLOUD,
  API_PDF_SERVICE
};
export default configs;
