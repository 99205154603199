import {
  GET_BIRTHPLACE_CITIES_SUCCESS,
  SET_DATA_FIELD_PERS1,
  SET_BIRTHPLACE_CITY_ID, SET_PERSONAL_INFO, SET_BIRTHPLACE,
} from '../actions/personal1';
import { whenFieldSelectIsZero } from '../config/utils';

const initialState = {
  loading: false,
  birthplaceCities: [],
  dataDefault:{},
  data:{},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BIRTHPLACE_CITIES_SUCCESS:      
      return {
        ...state,  
        birthplaceCities: action.data,         
      }; 
    case SET_DATA_FIELD_PERS1:           
      return {
        ...state,        
        data:{
          ...state.data,
          [action.data.name]: action.data.value,
        }
      };
    case SET_BIRTHPLACE_CITY_ID:           
      return {
        ...state,        
        data:{
          ...state.data,
          birthplace_city_id: action.data,
        }
      };
    case SET_PERSONAL_INFO:
      const datestate = action.data.date_of_birth
      let subDate = datestate != null ? datestate.split("-") : ""
      const dataDefault ={
        ...state.data,
        fullname : action.data.fullname,
        phone : action.data.phone,
        gender : action.data.gender,
        birthplace_city_id : {
          ...state.data.birthplace_city_id,
          id:action.data.birthplace_city_id,
          name: whenFieldSelectIsZero(action.data,"birthplace_city_id","birthplace_city" ),
        },
        birthplace_city : action.data.birthplace_city,
        date_of_birth : action.data.date_of_birth,
        inputYear: datestate != null ? Number(subDate[0]) :"",
        inputMonth: datestate != null ? Number(subDate[1]) :"",
        inputDate: datestate != null ? Number(subDate[2]) :"",
        mother_name : action.data.mother_name,
      }
      return {
        ...state,
        dataDefault,
        data:dataDefault
      }
    case SET_BIRTHPLACE:
      return {
        ...state,
        data:{
          ...state.data,
          birthplace_city_id : {
            id:action.data.birthplaceCitiesTemp.code,
            name:action.data.birthplaceCitiesTemp.name,
          },
        }
      }
    default:
      return state;
  }
}
