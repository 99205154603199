import {
  GET_ADDRESS_CONFIG_SUCCESS,
} from '../actions/general';

const initialState = {
  profile:{    
    addressConfig: {},
  },
  user_id: "1",
  phone_code: "+62",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {    
    
    case GET_ADDRESS_CONFIG_SUCCESS:      
      return {
        ...state,        
        profile:{
          ...state.profile,
          addressConfig: action.data
        }
      };    
    
    default:
      return state;
  }
}
