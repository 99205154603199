import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as bankActions from '../actions/bank';
import history from '../history';
import {
  getBanksApi,
  getSourceOfFundsApi,
  saveBankApi,
  getBankApi, getFrequencyAverageApi
} from '../requests/bank';
import {postProfileUpdateApi} from '../requests/navigation'
import {userInfoLocalStorage} from '../config/utils'
import {BANK_INFO} from '../helpers/globalConst'

export function* getBanks(action) {
  try {
    const response = yield call(getBanksApi, action.data);
    let data = response.data;
    yield put({
      type: bankActions.GET_BANKS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: bankActions.GET_BANKS_FAILED,
      error: e,
    });
  }
}

export function* getFrequencyAverageIncome(action) {
  try {
    const response = yield call(getFrequencyAverageApi, action.data);
    let data = response.data;
    yield put({
      type: bankActions.GET_FREQUENCY_AVERAGE_INCOME_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: bankActions.GET_FREQUENCY_AVERAGE_INCOME_FAILED,
      error: e,
    });
  }
}
export function* getFrequencyAverageOutcome(action) {
  try {
    const response = yield call(getFrequencyAverageApi, action.data);
    let data = response.data;
    yield put({
      type: bankActions.GET_FREQUENCY_AVERAGE_OUTCOME_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: bankActions.GET_FREQUENCY_AVERAGE_OUTCOME_FAILED,
      error: e,
    });
  }
}


export function* getSourceOfFunds(action) {
  try {
    const response = yield call(getSourceOfFundsApi, action.data);
    let data = response.data;
    yield put({
      type: bankActions.GET_SOURCE_OF_FUNDS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: bankActions.GET_SOURCE_OF_FUNDS_FAILED,
      error: e,
    });
  }
}

export function* saveBank(action) {
  try {
    const before = JSON.stringify(action.item.defaultData.before)
    const after = JSON.stringify(action.item.defaultData.after)
    if (before !== after) {
      const response = yield call(saveBankApi, action.data);

      const authInfo = userInfoLocalStorage();
      if (authInfo.profile_completed) {
        yield call(postProfileUpdateApi, {"update_list" : [BANK_INFO]})
      }

      let data = response.data;
      yield put({
        type: bankActions.SAVE_BANK_SUCCESS,
        data,
      });

    } else yield put({ type: "NOT_LOADING" })
    history.push('lender-navigation');
  } catch (e) {
    yield put({
      type: bankActions.SAVE_BANK_FAILED,
      error: e,
    });
    history.push('lender-navigation');
  }
}

export function* getBank(action) {
  try {
    const response = yield call(getBankApi, action.data);
    let data = response.data;
    yield put({
      type: bankActions.GET_BANK_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: bankActions.GET_BANK_FAILED,
      error: e,
    });
  }
}

export default all([
  takeLatest(bankActions.GET_BANKS_REQUEST, getBanks),
  takeLatest(bankActions.GET_SOURCE_OF_FUNDS_REQUEST, getSourceOfFunds),
  takeLatest(bankActions.SAVE_BANK_REQUEST, saveBank),
  takeLatest(bankActions.GET_BANK_REQUEST, getBank),
  takeLatest(bankActions.GET_FREQUENCY_AVERAGE_INCOME_REQUEST, getFrequencyAverageIncome),
  takeLatest(bankActions.GET_FREQUENCY_AVERAGE_OUTCOME_REQUEST, getFrequencyAverageOutcome)
]);
