import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as addressActions from '../actions/address';
import {
  getProvincesApi,
  getCitiesApi,
  getDistrictsApi,
  getSubdistrictsApi,
} from '../requests/address';

export function* getProvinces(action) {
  try {
    const response = yield call(getProvincesApi, action.data);
    let data = response.data.provinsi;
    yield put({
      type: addressActions.GET_PROVINCES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressActions.GET_PROVINCES_FAILED,
      error: e,
    });
  }
}
export function* getCities(action) {
  try {
    const response = yield call(getCitiesApi, action.data);
    let data = response.data.kota;
    yield put({
      type: addressActions.GET_CITIES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressActions.GET_CITIES_FAILED,
      error: e,
    });
  }
}
export function* getDistricts(action) {
  try {
    const response = yield call(getDistrictsApi, action.data);
    let data = response.data.kecamatan;
    yield put({
      type: addressActions.GET_DISTRICTS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressActions.GET_DISTRICTS_FAILED,
      error: e,
    });
  }
}
export function* getSubdistricts(action) {
  try {
    const response = yield call(getSubdistrictsApi, action.data);
    let data = response.data.kelurahan;
    yield put({
      type: addressActions.GET_SUBDISTRICTS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: addressActions.GET_SUBDISTRICTS_FAILED,
      error: e,
    });
  }
}
export default all([
  takeLatest(addressActions.GET_PROVINCES_REQUEST, getProvinces),
  takeLatest(addressActions.GET_CITIES_REQUEST, getCities),
  takeLatest(addressActions.GET_DISTRICTS_REQUEST, getDistricts),
  takeLatest(addressActions.GET_SUBDISTRICTS_REQUEST, getSubdistricts),
]);
