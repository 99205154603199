import React from "react";
import { Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Routes from './routes';
import endpoint from "./helpers/getEndpoints";
import history from "./history";

fetch(endpoint.functionsUrl + 'download_wording')
.then(function(response) {
  return response.json();
}).then(function(wording) {
  localStorage.setItem('wording', JSON.stringify(wording));
});

const App: React.FC = () => (
  <Router history={history}>
    <HelmetProvider>
      <Routes />
    </HelmetProvider>
  </Router>
);

export default App;
