import 'dotenv/config'

export default {
  mekarBaseUrl: process.env.REACT_APP_MEKAR_BASE_URL ?? "",
  cookiesUrl: process.env.REACT_APP_COOKIES_URL ?? "",
  functionsUrl: process.env.REACT_APP_CLOUD_FUNCTION_URL ?? "",
  accountServiceUrl: process.env.REACT_APP_ACCOUNT_SERVICE_URL ?? "",
  profileServiceUrl: process.env.REACT_APP_PROFILE_SERVICE_URL ?? "",
  loanServiceUrl: process.env.REACT_APP_LOAN_SERVICE_URL ?? "",
};
