import { all, call, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as docActions from '../actions/document';
import * as taxActions from '../actions/tax';
import { toDataUrl } from '../config/utils';
import history from '../history';
import {
  getDocumentApi, postDocumentApi
} from '../requests/document';
import {postProfileUpdateApi} from '../requests/navigation'
import {userInfoLocalStorage} from '../config/utils'
import {DOCUMENT_INFO} from '../helpers/globalConst'


export function* getDocuments(action) {
  try {
    const response = yield call(getDocumentApi, action.data);
    let data = response.data;
    let image = data[action.image]
    let base64 = ''
    
    if(image){
      base64 = yield toDataUrl(image)
    }

    if (action.image ==="npwp_image") {
      yield put({
        type: taxActions.GET_TAX_NPWP_SUCCESS,
        image,
        base64
      });
    }else{
      yield put({
        type: docActions.GET_DOCUMENT_SUCCESS,
        data,
        image,
        base64
      });
    }
  }

  catch (e) {
    yield put({
      type: docActions.GET_DOCUMENT_FAILED,
      error: e,
    });
  }
}
export function* postDocument(action) {
  try {
    const before = JSON.stringify(action.item.base64Image.before)
    const after = JSON.stringify(action.item.base64Image.after)
    const cookies = new Cookies();
    if (before !== after) {
      const response = yield call(postDocumentApi, action.data);

      const authInfo = userInfoLocalStorage();
      if (authInfo.profile_completed) {
        yield call(postProfileUpdateApi, {"update_list" : [DOCUMENT_INFO]})
      }
      
      let data = response.data;
      yield put({
        type: docActions.POST_DOCUMENT_SUCCESS,
        data,
      });

    } else yield put({ type: "NOT_LOADING" })
    if (action.item.next) {
      history.push(action.item.push);
      cookies.set(localStorage.getItem('lenderId'), action.item.push);
    } else
      history.push({
        pathname: 'lender-navigation',
    });
  } catch (e) {
    yield put({
      type: docActions.POST_DOCUMENT_FAILED,
      error: e.response?.data,
    });
    if(e.response?.status !== 400){
      if (action.item.next) {
        history.push(action.item.push);
      } else
        history.push({
        pathname: 'lender-navigation',
      });
    }
    
  }
}

export default all([
  takeLatest(docActions.GET_DOCUMENT_REQUEST, getDocuments),
  takeLatest(docActions.POST_DOCUMENT_REQUEST, postDocument),
]);
