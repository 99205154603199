import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as taxActions from '../actions/tax';
import * as docActions from '../actions/document';
import history from '../history';
import { postDocumentApi } from '../requests/document';
import {
  getNpwpCitiesApi,
  saveTaxApi,
  getTaxApi,
} from '../requests/tax';
import {postProfileUpdateApi} from '../requests/navigation'
import {userInfoLocalStorage} from '../config/utils'
import {TAX_INFO, DOCUMENT_INFO} from '../helpers/globalConst'

export function* getNpwpCities(action) {
  try {
    const response = yield call(getNpwpCitiesApi, action.data);
    let data = response.data.city;
    yield put({
      type: taxActions.GET_NPWP_CITIES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: taxActions.GET_NPWP_CITIES_FAILED,
      error: e,
    });
  }
}

export function* saveTax(action) {
  try {

    if(!action.data?.senddata?.body?.is_npwp_sync_to_ktp){
      const response2 = yield call(postDocumentApi, action.data.npwpImage);
      let data2 = response2.data;
      yield put({
        type: docActions.POST_DOCUMENT_SUCCESS,
        data2,
      });
    }
    
    const response = yield call(saveTaxApi, action.data.senddata);
    let data = response.data;

    const authInfo = userInfoLocalStorage();
    if (authInfo.profile_completed) {
      yield call(postProfileUpdateApi, {"update_list" : [TAX_INFO, DOCUMENT_INFO]})
    }

    yield put({
      type: taxActions.SAVE_TAX_SUCCESS,
      data,
    });

    if (action.item.type === 'next') history.push('lender-bank');
    else history.push('lender-navigation');

  } catch (e) {
    yield put({
      type: taxActions.SAVE_TAX_FAILED,
      error: e,
    });
    if (action.item.type === 'next') history.push('lender-bank');
    else history.push('lender-navigation');
  }
}

export function* getTax(action) {
  try {
    const response = yield call(getTaxApi, action.data)
    let data = response.data;
    yield put({
      type: taxActions.GET_TAX_SUCCESS,
      data,
    })
  } catch (e) {
    yield put({
      type: taxActions.GET_TAX_FAILED,
      error: e,
    })
  }
}

export default all([
  takeLatest(taxActions.GET_NPWP_CITIES_REQUEST, getNpwpCities),
  takeLatest(taxActions.SAVE_TAX_REQUEST, saveTax),
  takeLatest(taxActions.GET_TAX_REQUEST, getTax)
]);
