import {GET_CREDENTIAL_ERROR, GET_CREDENTIAL_REQUEST, GET_CREDENTIAL_SUCCESS} from "../actions/credential";

const initialState = {
    data:{}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_CREDENTIAL_REQUEST:
            return {
                ...state,
                data: {},
            };  
        case GET_CREDENTIAL_SUCCESS:
            return {
                ...state,
                data: action.data,
            };
        case GET_CREDENTIAL_ERROR:
            return {
                ...state,
                data: {},
            }; 
        default:
            return state;
    }
}