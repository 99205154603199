import { defaultActionCreator } from './actionCreator';

export const GET_RELIGIONS_REQUEST = 'GET_RELIGIONS_REQUEST'
export const GET_RELIGIONS_SUCCESS = 'GET_RELIGIONS_SUCCESS'
export const GET_RELIGIONS_FAILED = 'GET_RELIGIONS_FAILED'
export const getReligionsRequest = defaultActionCreator(GET_RELIGIONS_REQUEST, 'data')
export const getReligionsSuccess = defaultActionCreator(GET_RELIGIONS_SUCCESS, 'data')
export const getReligionsFailed = defaultActionCreator(GET_RELIGIONS_FAILED, 'error')

export const SET_RELIGION_ID = 'SET_RELIGION_ID';
export const setReligionId = defaultActionCreator(SET_RELIGION_ID, 'data');

export const GET_MARITAL_STATUSES_REQUEST = 'GET_MARITAL_STATUSES_REQUEST'
export const GET_MARITAL_STATUSES_SUCCESS = 'GET_MARITAL_STATUSES_SUCCESS'
export const GET_MARITAL_STATUSES_FAILED = 'GET_MARITAL_STATUSES_FAILED'
export const getMaritalStatusesRequest = defaultActionCreator(GET_MARITAL_STATUSES_REQUEST, 'data')
export const getMaritalStatusesSuccess = defaultActionCreator(GET_MARITAL_STATUSES_SUCCESS, 'data')
export const getMaritalStatusesFailed = defaultActionCreator(GET_MARITAL_STATUSES_FAILED, 'error')

export const SET_MARITAL_STATUS_ID = 'SET_MARITAL_STATUS_ID';
export const setMaritalStatusId = defaultActionCreator(SET_MARITAL_STATUS_ID, 'data');

export const GET_EDUCATION_LEVELS_REQUEST = 'GET_EDUCATION_LEVELS_REQUEST'
export const GET_EDUCATION_LEVELS_SUCCESS = 'GET_EDUCATION_LEVELS_SUCCESS'
export const GET_EDUCATION_LEVELS_FAILED = 'GET_EDUCATION_LEVELS_FAILED'
export const getEducationLevelsRequest = defaultActionCreator(GET_EDUCATION_LEVELS_REQUEST, 'data')
export const getEducationLevelsSuccess = defaultActionCreator(GET_EDUCATION_LEVELS_SUCCESS, 'data')
export const getEducationLevelsFailed = defaultActionCreator(GET_EDUCATION_LEVELS_FAILED, 'error')

export const SET_EDUCATION_LEVEL_ID = 'SET_EDUCATION_LEVEL_ID';
export const setEducationLevelId = defaultActionCreator(SET_EDUCATION_LEVEL_ID, 'data');

export const GET_INVESTMENT_PURPOSES_REQUEST = 'GET_INVESTMENT_PURPOSES_REQUEST'
export const GET_INVESTMENT_PURPOSES_SUCCESS = 'GET_INVESTMENT_PURPOSES_SUCCESS'
export const GET_INVESTMENT_PURPOSES_FAILED = 'GET_INVESTMENT_PURPOSES_FAILED'
export const getInvestmentPurposesRequest = defaultActionCreator(GET_INVESTMENT_PURPOSES_REQUEST, 'data')
export const getInvestmentPurposesSuccess = defaultActionCreator(GET_INVESTMENT_PURPOSES_SUCCESS, 'data')
export const getInvestmentPurposesFailed = defaultActionCreator(GET_INVESTMENT_PURPOSES_FAILED, 'error')

export const SET_INVESTMENT_PURPOSE_ID = 'SET_INVESTMENT_PURPOSE_ID';
export const setInvestmentPurposeId = defaultActionCreator(SET_INVESTMENT_PURPOSE_ID, 'data');

export const SAVE_PERSONAL_INFO_REQUEST = 'SAVE_PERSONAL_INFO_REQUEST'
export const SAVE_PERSONAL_INFO_SUCCESS = 'SAVE_PERSONAL_INFO_SUCCESS'
export const SAVE_PERSONAL_INFO_FAILED = 'SAVE_PERSONAL_INFO_FAILED'
export const savePersonalInfoRequest = defaultActionCreator(SAVE_PERSONAL_INFO_REQUEST, 'data','item')
export const savePersonalInfoSuccess = defaultActionCreator(SAVE_PERSONAL_INFO_SUCCESS, 'data')
export const savePersonalInfoFailed = defaultActionCreator(SAVE_PERSONAL_INFO_FAILED, 'error')

export const GET_PERSONAL_INFO_REQUEST = 'GET_PERSONAL_INFO_REQUEST'
export const GET_PERSONAL_INFO_SUCCESS = 'GET_PERSONAL_INFO_SUCCESS'
export const GET_PERSONAL_INFO_FAILED = 'GET_PERSONAL_INFO_FAILED'
export const getPersonalInfoRequest = defaultActionCreator(GET_PERSONAL_INFO_REQUEST, 'data','from')
export const getPersonalInfoSuccess = defaultActionCreator(GET_PERSONAL_INFO_SUCCESS, 'data')
export const getPersonalInfoFailed = defaultActionCreator(GET_PERSONAL_INFO_FAILED, 'error')

export const SET_PERSONAL2_INFO = 'SET_PERSONAL2_INFO'
export const setPersonal2Info = defaultActionCreator(SET_PERSONAL2_INFO,'data')
