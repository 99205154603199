import {
  GET_NPWP_CITIES_SUCCESS, GET_TAX_SUCCESS,
  SET_DATA_FIELD_TAX,
  SET_NPWP_CITY_ID,
  SET_TAX, SAVE_TAX_FAILED, SAVE_TAX_REQUEST, SAVE_TAX_SUCCESS, GET_TAX_NPWP_SUCCESS, SET_STATE_TAX
} from '../actions/tax';
import { checkNameWithCode} from '../config/utils';

const initialState = {
  stateLoad:false,
  loading: false,
  npwpCities: [],
  isDataFetching:false,
  dataDefault: {},
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE_TAX:
      return {...state, [action.name]:action.value}
    case GET_NPWP_CITIES_SUCCESS:
      return {
        ...state,
        npwpCities: action.data,
        data : {
          ...state.data,
          npwp_city_id : {
            ...state.data.npwp_city_id,
            name : action.data.length ? checkNameWithCode(action.data, state.data.npwp_city_id?.id,true) : undefined
          }
        }
      };
    case SET_DATA_FIELD_TAX:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: action.data.value,
        }
      };
    case SET_NPWP_CITY_ID:
      return {
        ...state,
        data: {
          ...state.data,
          npwp_city_id: action.data,
        }
      };

    case GET_TAX_SUCCESS:
      const dataDefault = {
        is_npwp_sync_to_ktp: action.data.is_npwp_sync_to_ktp ? "Ya" : "Tidak",
        npwp_name: action.data.npwp_name,
        npwp: action.data.npwp,
        npwp_address: action.data.npwp_address,
        npwp_city_id: {
          id: action.data.npwp_city_id,
          name: action.data.npwp_city_id === 0 ? undefined : checkNameWithCode(state.npwpCities, action.data.npwp_city_id) ,
        },
        postal_code: action.data.postal_code,
      }
      return {
        ...state,
        isDataFetching:true,
        dataDefault: { ...state.dataDefault, ...dataDefault },
        data: { ...state.data, ...dataDefault }
      }

    case GET_TAX_NPWP_SUCCESS:
      const dataNpwpDefault = {
        npwp_image: action.image,
        npwp_base64: action.base64
      }
      return {
        ...state,
        dataDefault: { ...state.dataDefault, ...dataNpwpDefault },
        data: { ...state.data, ...dataNpwpDefault }
      }
    case SAVE_TAX_FAILED:
      return {
        ...state, ...initialState, loading: false
      }
    case SAVE_TAX_REQUEST:
      return { ...state, loading: true }
    case SAVE_TAX_SUCCESS:
      return { ...state, loading: false }
    case SET_TAX:
      return {
        ...state,
        data: {
          ...state.data,
          npwp_city_id: {
            id: action.data.npwpCitiesTemp.code,
            name: action.data.npwpCitiesTemp.name,
          }
        }
      }
    case "NOT_LOADING":
      return { ...state, loading: false }
    default:
      return state;
  }
}
